<template>
<div>
  <router-link :to="`/article/${article.no}?${article.no}`">
  <div class="article" v-if="article">
    <div class="article-img">
      <div class="d-flex justify-content-around flex-column h-100">
        <div class="image-wrapper">
          <img v-lazy="photoUrl" alt="">
        </div>
      </div>
    </div>
    <div class="article-txt p-2">
      <div class="d-flex justify-content-around flex-column h-100">
        <h1 class="title line-3">{{ article.title }}</h1>
        <h2 class="sub-title line-3">{{ article.subtitle }}</h2>
        <p class="guide-txt line-3">{{ article.brief }}</p>
        <div class="name-date-list">
          <div>
            <span class="article-date">{{ publishDate }}</span>
            <span class="article-author">{{ authorName }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  </router-link>
</div>
</template>

<script setup>
import { computed } from "vue";
import dotProp from 'dot-prop'
import { timeKey } from "@/plugins/mixins";

const props = defineProps({
  article: Object,
});

const photoUrl = computed(() => dotProp.get(props.article,'media.data.0.medium'))
const authorName = computed(() => dotProp.get(props.article,'author_name'))
const publishDate = computed(() => dotProp.get(props.article,'publish_at'))

</script>

<style lang="scss" scoped>
.article{
  padding: 7.5px 0;
  display: flex;
  flex-direction: row;
  min-height: 140px;
  img {
    transition: 0.5s;
  }
  &:hover img {
    transform: scale(1.1);
  }
  @include large{
    flex-direction: column;
    border-bottom: 0px solid #E3E4E5;
  }
  @include media-breakpoint-down(md){
    flex-direction: column;
  }
  border-bottom: 1px solid #E3E4E5;
  @include square{
    border-bottom: 0px solid #E3E4E5;
    padding: 15px 0;
  }
  .article-img{
    flex: 1 1 0;
    min-width: 45%;
    @include square{
      .image-wrapper{
      }
      img {
        object-fit: cover;
      }

      .image-wrapper::before {
        content: "";
        position: absolute;
        opacity: 0;
        width: 100%;
        display: block;
        padding-bottom: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1;
      }
      .image-wrapper:hover::before {
        opacity: 0.7;
      }

    }
  }
  .article-txt{
    flex: 1 1 0;
    min-width: 50%;
    @include small{
      flex: 2 1 0;
    }
    @include square{
      background-color: rgba(0,0,0,0.7);
      width: 95%;
      bottom: 7.5px;
      right: 0;
      margin: 0px 15px;
      position: absolute;
      z-index: 9;
    }
  }
  .title {
    color: #0C121C;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.4px;
    @include large{
      margin-top: 12px;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 3px;
    }
    @include small{
      font-size: 18px;
      line-height: 27px;
      font-weight: 700;
      margin-bottom: 0;
    }
    @include media-breakpoint-down(sm){
      margin-top: 0 !important;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
    }
    @include square{
      color: white;
    }
  }
  .sub-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    margin-bottom: 5px;
    @include large{
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
    }
    @include small{
      display: none;
    }
    @include media-breakpoint-down(sm){
      display: none;
    }
  }
  .guide-txt {
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 10px;
    @include large{
      font-size: 14px;
    }
    @include square{
      display: none;
    }
    @include media-breakpoint-down(sm){
      margin-bottom: 0px !important;
    }
  }
}



.name-date-list {
  display: flex;
  justify-content: space-between;
  color: #A6A8AC;
  font-size: 14px;
  @include media-breakpoint-down(sm){
    display: none;
  }
  @include small{
    display: none;
  }
  @include square{
    display: none;
  }
  a {
    color: #A6A8AC;
    &:hover {
      color: #D80C24;
    }
  }
  .article-date::after {
    content: "|";
    margin-left: 6px;
    padding-right: 6px;
  }
}
.image-wrapper {
  padding-bottom: 58%;
  @include small{
    padding-bottom: 58%;
  }
}

</style>
