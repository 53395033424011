<template>
<div class="container mb-5">
  <h1 class="title"><span>{{ keyword }}</span>全站搜尋結果</h1>
  <ul class="tab-list">
    <li :class="{active: chosenType.path == item.path}" v-for="item in tabList" :key="item.id">
        <router-link :to="{ path: item.path, query: { keyword, tag } }">
          {{ item.name }}
        </router-link>
    </li>
  </ul>
  <router-view :search="searchData" :query="{ keyword, tag }"></router-view>
</div>

</template>

<script>
import { PostService, ProductService, TagService } from "@/apis/service";
export default {
  props: {
    keyword: String,
    tag: String,
  },
  data() {
    return {
      search: {
        wines: 0,
        articles: 0,
      },
      searchData: {
        wines: [],
        articles: [],
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.fetchData(to))
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchData(to)
    next()
  },
  computed: {
    chosenType() {
      return {
        path: this.$route.path
      }
    },
    tabList() {
      const wineCount = this.search['wines']
      const articleCount = this.search['articles']
      const allCount = wineCount+articleCount
      return [
        { path: '/search/all', name: `全部(${allCount})`,},
        { path: '/search/wine', name: `商品(${wineCount})`,},
        { path: '/search/article', name: `文章(${articleCount})`,},
      ]
    },
  },
  methods: {
    async fetchData (to) {
      const wineQuery = {
        orderBy: 'id',
        sortedBy: 'desc',
        limit: 25,
      }
      const articleQuery = {
        orderBy: 'id',
        sortedBy: 'desc',
        limit: 25,
      }
      if (to.query.tag) {
        wineQuery['tag'] = to.query.tag
        articleQuery['tag'] = to.query.tag
        const tagResp = await TagService.get(to.query.tag_id)
        const tagData = tagResp.data.data;
        this.$store.commit('setMeta', {
          title: `${tagData.name} ${tagData.title}｜酒訊網`,
          og: {
            url: window.location.href,
            type: 'website',
            title: `${tagData.name} ${tagData.title}｜酒訊網`,
            description: `${tagData.name} ${tagData.description}`,
            image: 'https://drinker.s3.ap-northeast-1.amazonaws.com/logo.jpg',
          },
          meta: [
            {
              property: 'fb:app_id',
              content: 671097574106450,
            },
            {
              name: 'description',
              content: `${tagData.name} ${tagData.description}`,
            },
          ],
          link: [
            {
              href: window.location.href,
              rel: "canonical",
            }
          ],
        })
      }
      if (to.query.keyword) {
        const keyword = to.query.keyword.split(' ').join('|');
        wineQuery['search'] = `name:${keyword}`
        articleQuery['search'] = `title:${keyword};content:${keyword}`
      }
      const wineResp = await ProductService.query(wineQuery)
      this.search['wines'] = wineResp.data.meta.pagination.total
      this.searchData['wines'] = wineResp.data.data
      const articleResp = await PostService.query(articleQuery)
      this.search['articles'] = articleResp.data.meta.pagination.total
      this.searchData['articles'] = articleResp.data.data
      this.$store.commit('setLoading', false)
    },
  }
}
</script>

<script setup>
import AppTabPage from '@/components/app/TabPage.vue'
import BoxSearchWine from '@/components/box/search/Wine.vue'
import BoxSearchArticle from '@/components/box/search/Article.vue'
</script>

<style lang="scss" scoped>

.title{
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
  margin-bottom: 30px;
  span {
    color: #B20419;
    letter-spacing: 2px;
    font-weight: 500;
    margin-right: 8px;
  }
}

</style>
