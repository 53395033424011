<template>
<div :class="[ size ,'bg-gray']" v-if="event">
  <div class="event">
    <div class="event-img">
        <div class="image-wrapper">
          <img v-lazy="photoUrl" alt="">
        </div>
    </div>
    <div class="event-txt">
        <h2 class="title">{{ event.name }}</h2>
        <div class="info">
          <div class="sub">
              <div class="time"><i class="far fa-clock fa-fw"></i>{{ event.activity_time }}</div>
              <div class="location"><i class="fas fa-map-marker-alt fa-fw"></i>{{ event.address }}</div>
          </div>
          <router-link :to="`/article/${event.urls.post}?${event.urls.post}`">
            <div class="intro-txt" v-html="event.content"></div>
          </router-link>
          <div class="accupass-btn">
              <div class="purple-btn">
                  <a :href="event.urls.accupass" target="_blank">立即報名</a>
              </div>
          </div>
        </div>
    </div>
  </div>
</div>
</template>

<script setup>
import { defineProps, computed } from "vue";
import dotProp from 'dot-prop'
import { timeKey } from "@/plugins/mixins";
const props = defineProps({
  small: Boolean,
  large: Boolean,
  tiny: Boolean,
  event: Object,
  // photoUrl: String,
  // title: String,
  // subTitle: String,
  // content: String,
  // author: String,
  // date: String,
  // viewCount: Number,
});
const photoUrl = computed(() => dotProp.get(props.event,'media.data.0.medium'))
const size = computed(() => {
  if (props.small) {
    return 'small'
  }
  if (props.large) {
    return 'large'
  }
  if (props.tiny) {
    return 'tiny'
  }
  return 'normal'
})
</script>

<style lang="scss" scoped>
.event {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @include large {
    flex-direction: row;
  }
  background: white;
  padding: 25px;
  margin-bottom: 30px;
  @include small {
    padding: 0;
  }
  @include tiny {
    position: relative;
    display: block;
    padding: 0;
    margin: 0;
    &::before {
      content: "";
      position: relative;
      opacity: 0;
      width: 100%;
      display: block;
      padding-bottom: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }
    &:hover::before {
      opacity: 1;
    }
    .event-img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      .image-wrapper{
        padding-bottom: 100%;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &:hover .title{
      display: block;
    }
    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      color: #fff;
      z-index: 1;
      transform: translate(-50%, -50%);
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 1px;
      white-space: nowrap;
      text-align: center;
      display: none;
      @include media-breakpoint-down(sm) {
        font-size: 14px;
      }
    }
  }

  .event-img {
    width: 100%;
    flex: 1 1 0;
  }
  .event-txt {
    flex: 1 1 0;
    margin: 10px;
    @include large{
      flex: 1.5 1 0;
    }
    .title {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
        @include small {
          font-size: 18px;
          line-height: 27px;
        }
    }

    .sub {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      .location {
        @include small {
          display: none;
        }
      }
      .time {
        @include small {
          font-size: 12px;
          line-height: 21px;
          padding-top: 8px;
        }
      }
      div {
        margin-bottom: 6px;

        i {
          color: #B20419;
          margin-right: 8px;
        }
      }
    }

    .intro-txt {
      font-size: 14px;
      margin: 10px 0 20px;
      padding-top: 10px;
      width: 100%;
      background-image: linear-gradient(to right, #F0C029 0%, #F0C029 50%, transparent 50%);
      background-size: 20px 1px;
      background-repeat: repeat-x;
      @include small {
        display: none;
      }
    }

    .accupass-btn {
      text-align: right;

      .purple-btn {
        font-size: 18px;

        a {
          padding: 8px 30px;
        }
      }
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  padding-top: 10px;
  justify-content: space-between;
  @include small {
    background-image: linear-gradient(to right, #F0C029 0%, #F0C029 50%, transparent 50%);
    background-size: 20px 1px;
    background-repeat: repeat-x;
    flex-wrap: nowrap;
    flex-direction: row;
    div {
      flex: 1 0 auto;
    }
  }
  @include tiny {
    display: none;
  }
}
</style>
