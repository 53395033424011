<template>
<ul class="tab-list">
  <li :class="{active: chosen.id == item.id}" v-for="item in list" :key="item.id" @click="chose(item)">
      {{ item.name }}
  </li>
</ul>
</template>

<script setup>
import { ref, computed } from 'vue'
const props = defineProps({
  list: Array,
  chosen: null,
});
const emit = defineEmits(['change']);
const chose = (item) => {
  emit('change', item)
}
</script>


<style lang="scss" scoped>
ul.tab-list {
  display: inline-flex;
  margin: 0 0 0 20px;
  overflow-x: hidden;
  li {
    padding: 4px 5px;
    font-size: 18px;
    border-bottom: 3px solid #E3E4E5;
    flex: 0 0 auto;
    cursor: pointer;
    &.active {
      border-bottom: 3px solid #D80C24;
      color: #D80C24;
    }
  }
}
</style>