<template>
  <div>
      <div class="score p-3" v-if="score">
        <div class="score-img  ">
          <div class="d-flex justify-content-around flex-column h-100">
            <div class="image-wrapper">
              <img v-lazy="photoUrl" alt="">
            </div>
            <div class="">
              <div class="py-2 score-star star-buy">
                  <span class="h5 py-2">購買指數：</span><br><br>
                  <i v-for="n in stars" class="fas fa-star fa-lg fa-fw mobile-icon-star text-warning"></i>
                  <i v-for="n in 5-stars" class="fas fa-star fa-lg fa-fw mobile-icon-star"></i>
              </div>
              <div class="py-2 score-star star-leo h5"><span >Leo's points：</span>
                <span class="text-danger">
                {{ parseInt(score.score_by_leo) }}
              </span></div>
            </div>
            <div class="image-title">
              <div class="title-en">{{ score.name_en }}</div>
              <div class="title">{{ score.name }}</div>
            </div>
          </div>
        </div>
        <div class="score-txt p-2">
          <div class="d-flex flex-column h-100">
            <span class="title-en">{{ score.name_en }}</span>
            <span class="title">{{ score.name }}</span>
            <ul class="score-notes">
              <li class="" v-for="note, key in notes">{{ note }}</li>
            </ul>
            <span class="score-content" v-html="score.content"></span>
            <span class="score-date">{{ drinkAt }}</span>
          </div>
        </div>
        <div class="h5 more-label text-right"> MORE<i class="fa fa-angle-double-right" aria-hidden="true"></i></div>
      </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import dotProp from 'dot-prop'
import { timeKey } from "@/plugins/mixins";
const props = defineProps({
  score: Object,
});

const photoUrl = computed(() => dotProp.get(props.score,'media.0.original_url'))
const drinkAt = computed(() => {
  if (props.score.drink_at) {
    return new Date(props.score.drink_at).toISOString().slice(0, 10).replaceAll("-", "")
  }
  return "";
})
const notes = computed(() => {
  let notes = []
  if (props.score.note) {
    notes = props.score.note.split('\n')
  }
  return ['酒精度數：' + props.score.alcohol_by_volume + '%'].concat(notes)
})

const stars = computed(() => {
  if (props.score.stars) {
    return props.score.stars
  }
  return 0
})
</script>

<style lang="scss" scoped>
.score {
  width: 100%;
  display: flex;
  flex-direction: row;
  @include media-breakpoint-down(md){
    flex-direction: column;
  }
  // margin: 20px 12px;
  background-color: #F9F9F9;
  .score-img{
    br {
      display: none;
    }
    // flex: 1 1 0;
    flex-grow: 1;
    min-width: 45%;
    
    @include media-breakpoint-down(md){
      @include small{
        .image-wrapper{
          order: 99;

        }
        br {
          display: block;
        }
        .score-star{
          width: 50%;
          display: inline-block;
          padding: 1rem 0;
        }
        .star-leo {
          text-align: right;
        }
      }
    }
  }
  .score-txt{
    @include media-breakpoint-down(md){
      @include small{
        display: none;
      }
    }
    min-width: 50%;

  }
  .score-notes {
    margin: 10px 20px;
    // padding: 10px 20px;
    font-size: 16px;
    color: #505D71;
    li{
      list-style: disc !important;
    }
  }
  .score-content{
    font-size: 16px;
    color: #2a313c;
    flex-grow: 1;
  }
  .title-en{
      font-size: 18px;
      font-weight: 500;      
      padding: 0.5rem 0;
    }
  .title{
    font-size: 24px;
    font-weight: 500;      
    padding: 0.5rem 0;
  }
  .image-title{
    display: none;
    @include media-breakpoint-down(md){
      @include small{
        display: block;
      }
    }
  }
  .more-label{
    padding-top: 1rem;
    display: none;
    @include media-breakpoint-down(md){
      @include small{
        display: block;
      }
    }
  }
}

.scrore-warpper {
  border-radius: 6px;
  transition: 0.5s;
  // &:hover {
  //   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  //   transform: scale(1.02);
  // }
}




// .item-pic {
//   background-color: #F7F3F0;
//   position: relative;
//   min-height: 180px;
//   text-align: center;

//   img {
//     position: relative;
//     bottom: -13px;
//     max-width: 180px;
//     width: 100%;
//     margin: 0 auto;
//   }
// }

// .item-txt {
//   background-color: #F9F9F9;
//   text-align: center;
//   padding: 15px;

//   .ch-name {
//     font-size: 16px;
//     font-weight: 700;
//     line-height: 24px;
//     height: 48px;
//   }

//   .en-name {
//     font-size: 12px;
//     height: 36px;
//   }

//   .price {
//     font-size: 18px;
//     line-height: 27px;
//     font-weight: 700;
//     color: #B20419;
//     margin: 3px 0;
//   }
// }

// .purple-btn {
//   background-color: #542836;
//   border-radius: 10px;
//   font-weight: 700;
//   display: inline-block;
//   white-space: nowrap;
//   padding: 3px 15px;

//   i {
//     @include media-breakpoint-down(xs) {
//       display: none;
//     }
//   }

//   span {
//     display: inline-block;
//     color: #fff;
//   }
// }

// .medal-score {
//   background-image: url(~@/assets/images/icon/ico-black-score.png);
//   background-size: 50px 50px;
//   position: absolute;
//   top: 10px;
//   left: 10px;
//   width: 50px;
//   height: 50px;

//   span {
//     color: #fff;
//     font-size: 28px;
//     font-weight: 500;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//   }
// }</style>
