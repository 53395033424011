<template>
<div class="section pt-5">
  <h3 class="section-title"><span>服務條款與隱私權聲明</span></h3>
  <div class="content-box">
      <p>《WSD酒訊雜誌網》係由酒訊文化事業股份有限公司所經營，在您使用本網站相關服務之前，請詳讀以下相關條款。只要您存取或使用產品和服務包含瀏覽頁面，即表示您即同意遵守下列條款與條件（以下簡稱「本服務條款」與「本條款」），包含本文提及的附加條款和條件與政策與超連結。這些服務條款適用於本網站的所有使用者，包含但不限於瀏覽者、廠商、顧客、商家或提供內容的人；若您為未滿二十歲或無完全行為能力者，您的家長、法定監護人或代理人應同時確實閱讀、瞭解並同意遵守本條款之所有內容，方能繼續使用本網站之相關服務。</p>
      <p>我們有權自行決定是否更新、變更或取代這些服務條款的任何部分，在本網站張貼最新訊息或變動事項。您需定期檢視這些服務條款是否有所變更，如果您不同意修正過的服務條款，請勿存取或使用本網站、本服務與本內容。如果您在服務條款變更後持續使用或存取本網站、本服務或本內容，及代表您接受變更後的服務條款。</p>
      <ul class="main-list">
          <li @click="toggle(1)">
              <i class="fas fa-caret-right fa-lg mr-3"></i>
              A.隱私權保護政策
              <ol class="sub-list" :class="{active: 1 == openIndex}" >
                  <li>
                      <div class="title"><span class="big-txt">1</span>隱私權保護政策的適用範圍</div>
                      隱私權保護政策內容，包括本網站如何處理在您使用網站服務時蒐集到的個人識別資料。隱私權保護政策不適用於本網站以外的相關連結網站，也不適用於非本網站所委託或參與管理的人員。
                  </li>
                  <li>
                      <div class="title"><span class="big-txt">2</span>個人資料的蒐集、處理及利用方式</div>
                      當您造訪本網站或使用本網站所提供之功能服務時，我們將視該服務功能性質，請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；非經您書面同意，本網站不會將個人資料用於其他用途。 本網站在您使用服務信箱、問卷調查等互動性功能時，會保留您所提供的姓名、電子郵件地址、聯絡方式及使用時間等。 於一般瀏覽時，伺服器會自行記錄相關行徑，包括您使用連線設備的IP位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等，做為我們增進網站服務的參考依據，此記錄為內部應用，決不對外公佈。 為提供精確的服務，我們會將蒐集的問卷調查內容進行統計與分析，分析結果之統計數據或說明文字呈現，除供內部研究外，我們會視需要公佈統計數據及說明文字，但不涉及特定個人之資料。 提供個人化的服務，包括內容和廣告 - 我們會用您所提供的個人資料，為您打造個人化的服務，包括個人化內容或建議活動。 本網站與第三方服務可能會根據您的興趣向您顯示個人化廣告。
                  </li>
                  <li>
                      <div class="title"><span class="big-txt">3</span>資料之保護</div>
                      本網站主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料採用嚴格的保護措施，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。 如因業務需要有必要委託其他單位提供服務時，本網站亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實守。
                  </li>
                  <li>
                      <div class="title"><span class="big-txt">4</span>網站對外的相關連結</div>
                      本網站的網頁提供其他網站的網路連結，您也可經由本網站所提供的連結，點選進入其他網站。但該連結網站不適用本網站的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。                  </li>
                  <li>
                      <div class="title"><span class="big-txt">5</span>與第三人共用個人資料之政策</div>
                      本網站絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。
                      <ul class="third-list">
                          <span>前項但書之情形包括不限於：</span>
                          <li>經由您書面同意。</li>
                          <li>法律明文規定。</li>
                          <li>為免除您生命、身體、自由或財產上之危險。</li>
                          <li>與公務機關或學術研究機構合作，基於公共利益為統計或學術研究而有必要，且資料經過提供者處理或蒐集者依其揭露方式無從識別特定之當事人。</li>
                          <li>當您在網站的行為，違反服務條款或可能損害或妨礙網站與其他使用者權益或導致任何人遭受損害時，經網站管理單位研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。</li>
                          <li>有利於您的權益。</li>
                          <li>本網站委託廠商協助蒐集、處理或利用您的個人資料時，將對委外廠商或個人善盡監督管理之責。</li>
                          <li>本網站或本公司被其他公司收購或合併，我們有權利將您的個人資料移轉給該公司。如果發生這種情況，本網站會在您的個人資料被移轉且將適用不同的隱私權政策前通知您。</li>
                      </ul>
                  </li>
                  <li>
                      <div class="title"><span class="big-txt">6</span>Cookie之使用</div>
                      為了提供您最佳的服務，本網站會在您的電腦中放置並取用我們的Cookie，若您不願接受Cookie的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕Cookie的寫入，但可能會導致網站某些功能無法正常執行。                  </li>
                  <li>
                      <div class="title"><span class="big-txt">7</span>隱私權保護政策之修正</div>
                      我們將因應需求擁有隨時修改本隱私權保護政策的權利，當我們做出修改時，會於本網站公告，且自公告日起生效，不再另行通知。
                  </li>
              </ol>
          </li>
          <li @click="toggle(2)">
              <i class="fas fa-caret-right fa-lg mr-3"></i>
              B.著作權聲明
              <p class="sub-list" :class="{active: 2 == openIndex}">《WSD酒訊雜誌網》中所刊載的所有內容，包括文字、照片、影像、錄音、插圖等素材，均受中華民國著作權法及國際著作權法律的保障。網站內容的著作權為酒訊文化事業股份有限公司以及其它授權《WSD酒訊雜誌網》的內容提供者共同所有。《WSD酒訊雜誌網》所提供的內容及服務非經公司正式書面授權同意，不得將全部或部份內容，以任何形式變更、轉載、再製、散佈、出版、展示或傳播。</p>
          </li>
          <li @click="toggle(3)">
              <i class="fas fa-caret-right fa-lg mr-3"></i>
              C.免責聲明
              <ol class="sub-list" :class="{active: 3 == openIndex}">
                  <li>
                      <span class="big-txt">1</span>《WSD酒訊雜誌網》所載資訊是為本公司其控股、聯屬及附屬公司所提供的各項服務而準備及製作，本公司對其所載的資訊完整性及準確性不作任何保證，於任何情況下，本公司均不會就任何人士使用本網站的應用程式、內容、貨品或服務而導致或蒙受的一切損失，包括直接、間接或相應而產生的損失，或關乎疏忽、合同或其他形式的侵權或其他性質的訴訟，而承擔任何法律責任或作出任何賠償。
                  </li>
                  <li>
                      <span class="big-txt">2</span>本公司會盡力將儲存在《WSD酒訊雜誌網》系統內的個人資料保密，免受到不必要的盜取及使用。但鑑於互聯網的性質，本公司不能對資料傳送安全和保密作出完全保證或聲明。若閣下發現個人資料在未經授權下遭洩漏，請立即與本公司聯絡。
                  </li>
                  <li>
                      <span class="big-txt">3</span>與《WSD酒訊雜誌網》連結的其他網站，也可能蒐集閣下的個人資料。《WSD酒訊雜誌網》的隱私政策將不適用於瀏覽者從《WSD酒訊雜誌網》連結到其他網站及繼續閱讀其他網站時被收集個人資料，閣下須自行承擔有關之風險，本公司對於連結其他網站上所載資訊的準確性、有效性、安全性或如有任何遺漏，均不承擔任何責任。
                  </li>
                  <li>
                      <span class="big-txt">4</span>客戶於網上購買《WSD酒訊雜誌網》的貨品及服務時，所使用的網上付款系統並不一定在《WSD酒訊雜誌網》內進行，客戶使用《WSD酒訊雜誌網》以外的網上付款系統時，必須理解及明白網上付款系統網站內所列明的使用政策及隱私政策等資料。酒訊文化事業股份有限公司的隱私政策將不適用於所有網上付款系統網站內。
                  </li>
              </ol>
          </li>
          <li @click="toggle(4)">
              <i class="fas fa-caret-right fa-lg mr-3"></i>
              D.平台服務條款
              <ol class="sub-list" :class="{active: 4 == openIndex}">
                  <li>
                      <div class="title"><span class="big-txt">1</span>使用者</div>
                      如果您要在酒客網進行消費，您必須在首次訂購流程中先填入您所合法取得且仍有效使用之電子郵件信箱及手機號碼，並自行設定一組密碼；當您第一次在酒客網留存相關資料或完成消費，本服務系統將紀錄您所填入之電子郵件信箱及您所自行設定之密碼，您並同意日後應以該組電子郵件信箱及密碼登錄後於酒客網進行消費。您有自行妥善保管您留存於本公司系統之電子郵件帳號或手機號碼及所自行設定密碼之義務，不得將該電子郵件帳號或手機號碼及密碼透露或提供給第三人知悉、或出借或轉讓第三人使用。對於所有使用該組電子郵件帳號及密碼登入本服務系統所為之一切行為，您同意由您負其責任。若您發現您的電子郵件帳號及密碼遭人冒用，請即時通知酒客網將於獲悉您的電子郵件帳號及密碼遭冒用時，立即暫停該電子郵件帳號所生交易之處理及後續利用。
                  </li>
                  <li>
                      <div class="title"><span class="big-txt">2</span>個人資料安全</div>
                      對於您的個人資料，《WSD酒訊雜誌網》將遵守個人資料保護相關法令規定。關於個人資料保護及隱私權政策，請參閱A.隱私權保護政策。為了完成交易，包括且不限於完成付款及交付等，您必須擔保在訂購過程中所留存的所有資料均為完整、正確、與當時情況相符的資料，如果事後有變更，您應該即時通知本網站客服。對於您所留存的資料，《WSD酒訊雜誌網》除了採用安全交易模式外，並承諾負保密義務，除了為完成交易或提供顧客服務而提供給相關商品或服務之配合廠商，不會任意洩漏或提供給第三人。<br>
                      <ul class="third-list">
                          <span>在下列情況下，《WSD酒訊雜誌網》有權查看或提供您的個人資料給有權機關、或主張其權利受侵害並提出適當證明的第三人：</span>
                          <li>依法令規定、或依司法機關或其他有權機關的命令。</li>
                          <li>為完成交易或執行本約定條款、或您違反本約定條款。</li>
                          <li>為維護酒客網系統的正常運作及安全。</li>
                          <li>為保護酒客網、其他使用者或第三人的合法權益。</li>
                      </ul>
                  </li>
                  <li>
                      <div class="title"><span class="big-txt">3</span>線上訂購</div>
                      除本約定條款外，商品銷售網頁及訂購流程中相關網頁上所呈現之相關資訊，包括相關交易條件、限制及說明等，亦為契約之一部分。《WSD酒訊雜誌網》保留是否接受您的訂單的權利。您一旦在《WSD酒訊雜誌網》依照網頁所定方式、條件及流程完成訂購程序，就表示您提出要約、願意依照本約定條款及相關網頁上所載明的約定內容、交易條件或限制，訂購該商品或服務。您所留存的資料如地址、電話如有變更，應即時通知酒客網協助進行相關處理，而且您不得以資料不符為理由，否認訂購行為或拒絕付款。如果《WSD酒訊雜誌網》確認交易條件無誤、您所訂購之商品仍有存貨或所訂購之服務仍可提供、且無其他《WSD酒訊雜誌網》無法接受訂單之情形，《WSD酒訊雜誌網》會直接通知配合廠商出貨，不另行通知。若交易條件有誤、商品無存貨、服務無法提供、或有《WSD酒訊雜誌網》無法接受訂單之情形，《WSD酒訊雜誌網》得拒絕接受訂購之要約。
                      <br/>
                      您瞭解並同意，雖然《WSD酒訊雜誌網》會盡力維護相關資料的正確性，但《WSD酒訊雜誌網》不以任何明示或默示的方式保證所有出現在網頁上、或相關訊息上的資料均為完整、正確、即時的資訊。如果相關商品或服務的規格、圖片或說明有誤，仍以原廠、代理商、進口商、經銷商或服務提供者的資料為準。
                      <br/>
                      您所訂購的所有商品或服務，關於其品質、保固及售後服務等，都是由各該商品或服務的原廠、代理商、進口商、經銷商或服務提供者，依照其所制定的條件，負責對您提供品質承諾、保固及售後服務等，但《WSD酒訊雜誌網》承諾協助您解決關於因為線上消費所產生的疑問或爭議。
                      <br/>
                      關於您所訂購之商品或服務，其運費之計價及負擔方式，依各該商品或服務銷售網頁及訂購流程中相關網頁之記載定之；如未記載，其運費由《WSD酒訊雜誌網》負擔。您在《WSD酒訊雜誌網》所進行的所有線上消費及與該等交易有關之事項，您同意以電子文件為表示方法；《WSD酒訊雜誌網》電腦系統將自動紀錄相關電子交易資料，您亦可經由網路於登入系統後自行查詢相關交易資料，如果您發現交易資料不正確，應立即通知《WSD酒訊雜誌網》。
                      <br/>
                  </li>
                  <li>
                      <div class="title"><span class="big-txt">4</span>關於退貨退款</div>
                      如果您所訂購的商品有瑕疵，您可以要求全額退費或換貨。您可以依消費者保護法第十九條第一項之規定，行使相關權利。您所退回的商品，必須保持所有商品、贈品、附件、包裝、及所有附隨文件或資料在出貨當時狀態的完整性，如果有實體發票，並應連同發票一併退回及簽署折讓單等相關法令所要求之單據；否則，酒客網得拒絕接受您的退貨退款要求。
                      <br/>
                      您瞭解並同意，若因您要求退貨或換貨、或因《WSD酒訊雜誌網》無法接受您全部或部分之訂單、或契約因故解除或失其效力，而需為您辦理退款事宜時，酒客網得代您處理發票或折讓單等相關法令所要求之單據。
                      <br/>
                      申請退貨時，請利用LINE官方帳號「@gbi0903c」通知聯繫我們，我們將審核案件，案件將轉於廠商聯繫處理，敬請保持電話暢通，並且請將原商品備妥(以原寄給您時的包裝再原封備妥)，勿直接在商品原廠外盒書寫文字。 (宅配公司僅收件，商品由特約廠商驗收審核)。收到退回商品確認無誤後，則進入退款流程，如退款當中有需資料文件，請協助提供給我們。
                      <br/>
                  </li>
                  <li>
                      <div class="title"><span class="big-txt">5</span>免責聲明</div>
                      <ul class="third-list">
                          <span>您明確了解並同意《WSD酒訊雜誌網》對本服務不提供任何明示或默示的擔保，您使用本服務時須自行承擔相關風險，《WSD酒訊雜誌網》不擔保以下事項：</span>
                          <li>您經由本服務之使用而購買或取得之任何產品、服務、資訊或其他資料將符合您的期望。</li>
                          <li>任何第三人就本服務所為之聲明或行為。</li>
                          <li>使用或無法使用本服務。</li>
                          <li>他人未經授權存取或修改您的傳輸或資料。</li>
                          <li>您自本服務網站或經由本服務取得之建議和資訊，無論其為書面或口頭，有無與本服務條款牴觸，均不構成《WSD酒訊雜誌網》或本服務之擔保或保證。</li>
                      </ul>
                  </li>
              </ol>
          </li>
      </ul>
  </div>
</div>
</template>

<script setup>



import { ref } from 'vue'
const openIndex = ref(-1)

const toggle = (index) => {
  console.log(index)
  openIndex.value = index
}

</script>

<style lang="scss" scoped>
.main-title {
  margin-bottom: 50px;
}

.content-box {
  background: #fff;
  padding: 50px;
  font-size: 18px;
  line-height: 32px;

  ul.main-list {
    margin: 50px 0;

    i {
      transition: 0.2s;

      &.active {
        transform: rotate(90deg);
      }
    }

    li {
      margin: 24px 0;

      .sub-list {
        display: none;
        border-left: 5px solid #D3D5D7;

        &.active {
          display: block;
          margin-bottom: 50px;
          margin-left: 3px;
        }

        li {
          ul.third-list {
            padding: 20px 0 0 30px;
            list-style: disc;

            span {
              margin-left: -30px;
            }

            li {
              list-style: disc;
              margin: 6px 0;
            }
          }

          .title {
            font-weight: 700;
          }
        }
      }

      > p {
        margin: 24px 0;
        padding-left: 30px;
      }

      .big-txt {
        font-weight: 700;
        font-size: 32px;
        padding-right: 8px;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .content-box {
    padding: 20px;
  }
}
</style>
