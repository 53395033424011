<template>
  <div class="bg-gray">
    <div class="container main-container mb-5">
      <div class="row p-3">
        <div class="article-wrapper col-md-12 col-12 row">
          <ItemArticle v-for="article in articles" :key="article.no" :article="article"  class="square col-md-6 col-12" />
        </div>
          <AppPagination :meta="meta" @page="jump" class="mt-50 col-md-12"/>
      </div>
    </div>
  </div>
  </template>
  <script>
  import { PostService, CategoryService } from "@/apis/service";
  export default {
    data() {
      return {
        category: [],
        topArticles: [],
        firstArticles: [],
        articles: [],
        meta: [],
        tabList: [],
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => vm.fetchData(to))
    },
    beforeRouteUpdate(to, from, next) {
      this.fetchData(to)
      next()
    },
    methods: {
      async title (id) {
        const eachSearch = (array, callback) => {
          var t = []
          for (var i = 0; i < array.length; i++) {
            const item = array[i];
            const c = eachSearch(item.children.data)
            if (c.length) {
              t = t.concat([item.name].concat(c))
            }
            if (item.id == id) {
              t = t.concat([item.name])
            }
          }
          return t.join(' - ')
        }
        var category
  
        if (this.$store.state.category) {
          category = this.$store.state.category
        } else {
          const response = await CategoryService.query()
          category = response.data
        }
        const t = eachSearch(category)
        return t
      },
      async fetchData (to) {
        const categoryResp = await CategoryService.get(to.query.category)
        const articleResp = await PostService.query({
          orderBy: 'is_top;publish_at',
          sortedBy: 'desc',
          limit: 16,
          category_id: to.query.category,
          page: to.query.page
        })
        this.topArticles = []
        this.articles = articleResp.data.data
        this.category = categoryResp.data.data[0]
        this.meta = articleResp.data.meta
        const title = await this.title(to.query.category)
        this.$store.commit('setMeta', {
          title: `${title}｜酒訊網`,
          og: {
            url: window.location.href,
            type: 'website',
            title: `${title}｜酒訊網`,
            description: '《WSD酒訊雜誌》於1990年成立，是台灣酒界唯一專業月刊雜誌，2020年啟動網路轉型計畫。主辦過「艾雷島嘉年華」、「威士忌嘉年華」、「台北葡萄酒生活節」、「日本酒主義」、「TOP 100 WINES百大葡萄酒展」、「西班牙La Mancha產區品酒會」、「葡萄牙品酒會」等百場活動。曾出版《Whisky100》、《40度以上的天堂烈酒購買指南》、《好瓶!時尚酒莊葡萄酒選購指南》、《威語錄》等叢書。',
            image: 'https://drinker.s3.ap-northeast-1.amazonaws.com/logo.jpg',
          },
          meta: [
            {
              property: 'fb:app_id',
              content: 671097574106450,
            },
            {
              name: 'description',
              content: '《WSD酒訊雜誌》於1990年成立，是台灣酒界唯一專業月刊雜誌，2020年啟動網路轉型計畫。主辦過「艾雷島嘉年華」、「威士忌嘉年華」、「台北葡萄酒生活節」、「日本酒主義」、「TOP 100 WINES百大葡萄酒展」、「西班牙La Mancha產區品酒會」、「葡萄牙品酒會」等百場活動。曾出版《Whisky100》、《40度以上的天堂烈酒購買指南》、《好瓶!時尚酒莊葡萄酒選購指南》、《威語錄》等叢書。',
            },
          ],
          link: [
            {
              href: window.location.href,
              rel: "canonical",
            }
          ],
        })
        this.$store.commit('setLoading', false)
      },
      jump(page) {
        this.$router.push({ path: '/layout', query: { category: this.category.id ,page }})
      }
    }
  }
  </script>
  
  <script setup>
  import ItemArticle from '@/components/item/Article.vue'
  import AppPagination from '@/components/app/Pagination.vue'
  </script>
  
  <style lang="scss" scoped>
  ::v-deep(.article-wrapper){
  }
  </style>
  