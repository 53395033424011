<template>
<div class="current-magazine" v-if="magazine">
  <div class="title">當期雜誌</div>
  <div class="cover-img">
      <img v-lazy="photoUrl" alt="">
  </div>
  <div class="magazine-txt">
      <div class="magazine-name">
        {{ magazine.name }}
      </div>
      <div class="magazine-publication">
          <!-- Jan, 2021 -->
      </div>
      <ul class="read-group">
          <li v-for="article in articles" :key="article.no">
              <span>試閱</span>
              <router-link :to="`/article/${article.no}?${article.no}`" class="line-2">
                {{ article.title }}
              </router-link>
          </li>
      </ul>
      <div class="border-btn">
          <a href="https://lin.ee/xuVds1R">訂閱雜誌</a>
      </div>
  </div>
</div>
</template>

<script setup>
import dotProp from 'dot-prop'
import { computed } from "vue";
import { useStore } from "vuex";
import { timeKey } from "@/plugins/mixins";
const store = useStore()
store.dispatch('getMagazine')
const magazine = computed(() => store.state.magazine)
const photoUrl = computed(() => dotProp.get(magazine.value,'medias.data.0.medium'))
const articles = computed(() => dotProp.get(magazine.value,'postables.data'))

</script>

<style lang="scss" scoped>
.current-magazine {
  text-align: center;

  .title {
    font-size: 20px;
    padding-bottom: 5px;
    border-bottom: 4px solid #D80C24;
    text-align: left;
  }

  .cover-img {
    padding: 15px;

    img {
      max-width: 200px;
      width: 100%;
    }
  }

  .magazine-txt {
    padding: 0 15px;

    ul.read-group {
      color: #0C121C;
      margin-top: 15px;
      text-align: left;

      li {
        display: -webkit-box;
        margin-bottom: 10px;

        span {
        padding: 3px 10px;
          background: #D80C24;
          color: #fff;
          border-radius: 6px;
          margin-right: 5px;
          font-size: 14px;
        }

        a {
          width: calc(100% - 53px);
          vertical-align: top;

          &:hover {
            color: #2A313C;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
