<template>
<div class="wine p-5">
  <div class="wine-warpper">
    <router-link :to="`/product/${wine.no}?${wine.no}`">
      <div class="item-pic">
        <img :data-splide-lazy="photoUrl" alt="" v-if="slide">
        <img v-lazy="photoUrl" alt="" v-if="!slide">
      </div>
    </router-link>
      <div class="item-txt">
        <router-link :to="`/product/${wine.no}?${wine.no}`">
          <h5 class="ch-name line-2">{{ wine.name }}</h5>
          <div class="en-name line-2">{{ wine.name_en }}</div>
          <div class="price">{{ price }}</div>
        </router-link>
      </div>
  </div>
</div>
</template>

<script setup>
import { computed } from "vue";
import dotProp from 'dot-prop'
import { timeKey } from "@/plugins/mixins";
const props = defineProps({
  wine: Object,
  slide: false,
});

const photoUrl = computed(() => dotProp.get(props.wine,'medias.data.0.medium') || dotProp.get(props.wine,'media.data.0.medium'))
const price = computed(() => props.wine.price.toLocaleString('zh-TW', {style: 'currency',currency: 'TWD', minimumFractionDigits: 0}))

</script>

<style lang="scss" scoped>
.wine {
  width: 100%;
}

.wine-warpper {
  border-radius: 6px;
  transition: 0.5s;
  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    transform: scale(1.02);
  }
}


.item-pic {
  background-color: #F7F3F0;
  position: relative;
  min-height: 180px;
  text-align: center;
  img {
    position: relative;
    bottom: -13px;
    max-width: 180px;
    width: 100%;
    margin: 0 auto;
  }
}

.item-txt {
  background-color: #F9F9F9;
  text-align: center;
  padding: 15px;
  .ch-name {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    height: 48px;
  }
  .en-name {
    font-size: 12px;
    height: 36px;
  }
  .price {
    font-size: 18px;
    line-height: 27px;
    font-weight: 700;
    color: #B20419;
    margin: 3px 0;
  }
}

.purple-btn {
  background-color: #542836;
  border-radius: 10px;
  font-weight: 700;
  display: inline-block;
  white-space: nowrap;
  padding: 3px 15px;
  i {
    @include media-breakpoint-down(xs){
      display: none;
    }
  }
  span {
    display: inline-block;
    color: #fff;
  }
}

.medal-score {
  background-image: url(~@/assets/images/icon/ico-black-score.png);
  background-size: 50px 50px;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 50px;
  height: 50px;
  span {
    color: #fff;
    font-size: 28px;
    font-weight: 500;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

</style>
