<template>
<div>
  <div class="container main-container">
    <BoxCooperation />
  </div>
</div>
</template>

<script setup>
import BoxCooperation from '@/components/box/Cooperation.vue'
import { useStore } from 'vuex'
const store = useStore()
store.commit('setBreadcrumb',[{
  name: '廣告合作',
  to: '/cooperation',
}])
store.commit('setLoading', false)
</script>
