<template>
<nav aria-label="navigation" v-if="'pagination' in meta">
  <ul class="pagination justify-content-center" v-if="meta.pagination.total_pages > 1">
    <li class="page-item" v-if="'previous' in meta.pagination.links">
      <a class="page-link" @click="emit('page', meta.pagination.current_page - 1)"><i class="fas fa-arrow-left"></i></a>
    </li>
    <li class="page-item" :class="{active: 1 == meta.pagination.current_page}">
      <a class="page-link" @click="emit('page', 1)">1</a>
    </li>
    <li class="page-item disabled" v-if="moreStart > 2">
      <span class="page-link">...</span>
    </li>
    <li class="page-item" v-for="pageNo in morePageRange" :key="pageNo" :class="{active: pageNo == meta.pagination.current_page}">
      <a class="page-link" @click="emit('page', pageNo)">{{ pageNo }}</a>
    </li>
    <li class="page-item disabled" v-if="moreEnd < meta.pagination.total_pages - 1">
      <span class="page-link">...</span>
    </li>
    <li class="page-item" :class="{active: meta.pagination.total_pages == meta.pagination.current_page}">
      <a class="page-link" @click="emit('page', meta.pagination.total_pages)">{{ meta.pagination.total_pages }}</a>
    </li>
    <li class="page-item" v-if="'next' in meta.pagination.links">
      <a class="page-link" @click="emit('page', meta.pagination.current_page + 1)"><i class="fas fa-arrow-right"></i></a>
    </li>
  </ul>
</nav>
</template>

<script setup>
import { computed } from "vue";
import dotProp from 'dot-prop'
const props = defineProps({
  meta: Object,
});
const emit = defineEmits(['page']);

const moreStart = computed(() => {
  let start = Math.min(props.meta.pagination.current_page - 2, props.meta.pagination.total_pages - 4)
  if (start < 2) {
    start = 2
  }
  return start
})
const moreEnd = computed(() => {
  let end =  Math.max(props.meta.pagination.current_page + 2, 5)
  if (end > props.meta.pagination.total_pages-1){
    end = props.meta.pagination.total_pages -1
  }
  return end
})

const morePageRange = computed(() => {
  let list = [];
  for (var i = moreStart.value; i <= moreEnd.value; i++) {
      list.push(i);
  }
  return list
})

const pageQuery = (page) => {
  return Object.assign({...props.query}, {page})
}
</script>

<style lang="scss" scoped>
</style>