<template>
<div>
  <template v-if="wines.length">
    <div class="d-flex flex-wrap px-15">
      <div class="product" v-for="(wine, index) in wines" :key="index">
        <ItemWine :wine="wine"/>
      </div>
    </div>
    <AppPagination :meta="meta" @page="jump"/>
  </template>
  <template v-else>
    <div class="not-found">
      <h4 class="text-center">我們搜尋不到任何相關商品</h4>
      <h5 class="text-center">請試試其他關鍵字，或直接到査酒款搜尋</h5>
    </div>
  </template>
</div>

</template>

<script>
import { PostService, ProductService } from "@/apis/service";
export default {
  props: {
    query: Object,
  },
  data() {
    return {
      wines: [],
      meta: [],
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.fetchData(to))
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchData(to)
    next()
  },
  computed: {

  },
  methods: {
    async fetchData (to) {
      const wineQuery = {
        orderBy: 'id',
        sortedBy: 'desc',
        limit: 12,
        page: to.query.page,
      }
      if (to.query.tag) {
        wineQuery['tag'] = to.query.tag
      }
      if (to.query.keyword) {
        const keyword = to.query.keyword.split(' ').join('|');
        wineQuery['search'] = `name:${keyword}`
      }
      const wineResp = await ProductService.query(wineQuery)
      this.wines = wineResp.data.data
      this.meta = wineResp.data.meta
      this.$store.commit('setLoading', false)
    },
    jump(page) {
      const query = Object.assign({...this.query}, {page: page})
      this.$router.push({ path: '/search/wine', query})
    },
  }
}
</script>

<script setup>
import ItemWine from '@/components/item/Wine.vue'
import AppPagination from '@/components/app/Pagination.vue'
</script>

<style lang="scss" scoped>
.not-found{
  padding-top:20px;
  padding-bottom: 300px;
}
.product {
  width: 20%;
  @include media-breakpoint-down(xl){
    width: 25%;
  }
  @include media-breakpoint-down(md){
    width: 33%;
  }
  @include media-breakpoint-down(sm){
    width: 50%;
  }
  @include media-breakpoint-down(xs){
    width: 100%;
  }
}
</style>
