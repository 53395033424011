<template>
<div>
  <div class="filter-box">
    <div class="wine-tag-group">
      <ul class="d-flex flex-wrap">
        <template v-for="(filter, key) in filters">
          <template v-for="(tag, index) in filter.options" :key="index" @click="remove(tag.id)">
            <li @click="remove(tag.id)" v-if="resultArray.includes(tag.id.toString())">{{ tag.name }}</li>
          </template>
        </template>
      </ul>
      <div class="text-right">
        <button ref="clear" class="filter-clear" @click="clear">清除篩選</button>
      </div>
    </div>
  </div>
  <ul>
    <li class="sort-item" v-for="(filter, key) in filters" :key="key" :class="{active: isOpen[key]}" >
      <div class="sort-title" @click="toggle(key)">
        <span class="sort-icons">{{ filter.name }}</span>
        <i class="icon-double-arrow"></i>
      </div>
      <ul class="sort-item-title">
        <li v-for="(option, index) in filter.options" :key="index">
          <label class="container">
            <input type="checkbox" v-model="resultArray" :value="option.id.toString()">
            <span class="checkmark"></span>
            {{ option.name }}
          </label>
        </li>
      </ul>
    </li>
  </ul>
</div>
</template>

<script setup>
import { ref, reactive, watch, computed } from "vue";
const props = defineProps({
  type: String,
  category: Object,
  filters: Array,
});
const initialState = {}
const emit = defineEmits(['update']);
const resultArray = ref(props.filters)
const result = reactive({})
const isOpen = reactive({})
const filterTags = computed(() => Object.values(result).flatMap(x => x))
const filters = computed(() => {
  const options = {}
  if ('children' in props.category) {
    props.category.children.data.forEach(element => {
      result[element.id] = [],
      initialState[element.id] = [],
      isOpen[element.id] = true,
      options[element.id] = {
        name: element.name,
        options: element.children.data.map((e) => {
          var name = e.name
          return {
            id: e.id,
            name,
          }
        })
      }
    })
  }
  return options
})
watch(resultArray, (result, oldResult) => {
  emit('update', resultArray)
})


const toggle = (key) => {
  isOpen[key] = !isOpen[key]
}

const clear = () => {
  Object.assign(result, initialState);
  resultArray.value = [];
}

const remove = (value) => {
  const index = resultArray.value.indexOf(value);
  resultArray.value.splice(index, 1)
}


</script>

<style lang="scss" scoped>
.sort-item {
  font-size: 18px;
  font-weight: 700;
  overflow: hidden;
  .sort-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #2A313C;
    padding-bottom: 8px;
    cursor: pointer;
    margin-bottom: 20px;
  }
    .icon-double-arrow {
      transform: rotate(0deg);
      transition: 0.5s;
    }
  .sort-item-title {
    max-height: 0;
    transition: max-height 0.5s ease-out;
  }
  &.active {
    .sort-item-title {
      max-height: 500px;
      transition: max-height 0.5s ease-in;
    }
    .icon-double-arrow {
      transform: rotate(180deg);
      transition: 0.5s;
    }
  }
}

.sort-item .sort-item-title li {
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 35px;
  }

  .container {
    display: block;
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 18px;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 1px solid #6E7177;
    background-color: #fff;
  }

  .container input:checked ~ .checkmark {
    background-color: #6E7177;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .container {
    input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark:after {
      left: 5px;
      top: 0px;
      width: 7px;
      height: 13px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.filter-box {
  background-color: #F9F9F9;
  padding: 10px;
  margin-bottom: 30px;
  .wine-tag-group {
    padding: 0;
    ul {
      margin: 0 0 6px 0;
      li {
        background-color: #2A313C;
        padding: 5px;
        margin: 5px 3px 5px 0;
        color: #FFF;
        font-size: 14px;
        &::before {
          content: "X";
          color: #fff;
          margin: 0 7px 0 2px;
        }
      }
    }
    .filter-clear {
      border: 0;
      background: transparent;
      color: #B20419;
      font-weight: 700;
      text-decoration: underline;
    }
  }
}
</style>