<template>
<div>
  <div class="container main-container">
    <BoxSubscription/>
  </div>
</div>
</template>

<script setup>
import { onMounted } from "vue";
import BoxSubscription from '@/components/box/Subscription.vue'
import { useStore } from 'vuex'
const store = useStore()
store.commit('setBreadcrumb',[{
  name: '雜誌訂閱',
  to: '/subscription',
}])
store.commit('setLoading', false)
</script>

<style lang="scss" scoped>

</style>
