<template>
<div class="section">
  <splide :options="options" class="container" :slides="magazines">
    <slide v-for="magazine in magazines" :key="magazine">
      <img v-lazy="magazine.img" class="magazine">
    </slide>
  </splide>
  <h3 class="section-title"><span>雜誌訂閱</span></h3>
  <h2 class="pt-20"><span class="yellowbottom-line">酒訊會員專屬雜誌訂閱方案</span></h2>
  <h3 class="for-free">免費加入會員，馬上獲得<span>專屬優惠價！！！</span></h3>
  <div>
      <div class="row">
      <div class="col-md-3 col-sm-6 col-12 padding-10">
          <div class="program">
              <h4 class="title">
                  <span>一年/12期</span>
                  <span>平信專案</span>
              </h4>
              <div class="price">
                  <div class="mem h4">會員價<span>＄1,488</span></div> 
                  <div class="origin">原價<span>＄1,750</span></div> 
              </div>
              <button class="select-btn"><a href="https://forms.gle/nbMAcnvNtAhFLdWF8">選擇此方案</a></button>
          </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 padding-10">
          <div class="program">
              <h4 class="title">
                  <span>一年/12期</span>
                  <span>掛號專案</span>
              </h4>
              <div class="price">
                  <div class="mem h4">會員價<span>＄1,728</span></div> 
                  <div class="origin">原價<span>＄1,990</span></div> 
              </div>
              <button class="select-btn"><a href="https://forms.gle/nbMAcnvNtAhFLdWF8">選擇此方案</a></button>
          </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 padding-10">
          <div class="program">
              <h4 class="title">
                  <span>二年/24期</span>
                  <span>平信專案</span>
              </h4>
              <div class="price">
                  <div class="mem h4">會員價<span>＄2,800</span></div> 
                  <div class="origin">原價<span>＄3,150</span></div> 
              </div>
              <button class="select-btn"><a href="https://forms.gle/nbMAcnvNtAhFLdWF8">選擇此方案</a></button>
          </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 padding-10">
          <div class="program">
              <h4 class="title">
                  <span>二年/24期</span>
                  <span>掛號專案</span>
              </h4>
              <div class="price">
                  <div class="mem h4">會員價<span>＄3,280</span></div> 
                  <div class="origin">原價<span>＄3,630</span></div> 
              </div>
              <button class="select-btn"><a href="https://forms.gle/nbMAcnvNtAhFLdWF8">選擇此方案</a></button>
          </div>
      </div>
      </div>
  </div>
  <div class="subscription-txt">
      <div class="intro">
          <h4 class="redtitle-line">簡介</h4>   
          <div class="txt">
              <p>第一本中文酒類雜誌 《酒訊雜誌 Wine & Spirits Digest》前身為《酒客雜誌 Joker Magazine》，早期是由在媒體工作的劉傳宇發起，並邀集一群對酒類懷抱熱情的資深媒體人，在1990年創辦。</p> 
              <p>藉由對產業多年的豐富觀察與經驗，突破傳統思維，將各種酒類知識及市場動態資訊集結出刊，是當時中、港、台三地唯一以酒類消費及產業動態為主要內容的酒類專業雜誌。</p>
              <p>直到2006年，雜誌社經營階層改組，轉由酒客雜誌成員吳志彥、莊雲斌和幾位酒界專家接棒，並將雜誌更名為《酒訊雜誌》。</p>
          </div>
      </div>
      <div class="send">
          <h4 class="redtitle-line">雜誌寄送說明</h4>   
          <div class="txt">
              <p class="txt-bold">付款成功日的次期開始寄送。</p>
          </div>
      </div>
  </div>
</div>
</template>

<script setup>
import { Splide, SplideSlide as slide } from '@splidejs/vue-splide';
const magazines = [
  {
    img: require("@/assets/images/product-type/magazine/mc170.jpg")
  },
  {
    img: require("@/assets/images/product-type/magazine/mc171.jpg")
  },
  {
    img: require("@/assets/images/product-type/magazine/mc172.jpg")
  },
  {
    img: require("@/assets/images/product-type/magazine/mc173.jpg")
  },
  {
    img: require("@/assets/images/product-type/magazine/mc174.jpg")
  },
  {
    img: require("@/assets/images/product-type/magazine/mc175.jpg")
  },
  {
    img: require("@/assets/images/product-type/magazine/mc171.jpg")
  },
  {
    img: require("@/assets/images/product-type/magazine/mc172.jpg")
  },
  {
    img: require("@/assets/images/product-type/magazine/mc173.jpg")
  },
]
const options = {
  autoWidth: true,
  autoplay: true,
  arrows: false,
  pagination: false,
}
</script>

<style lang="scss" scoped>
.subscription-page h2 {
  margin-bottom: 20px;
}

.yellowbottom-line {
  border-bottom: 10px solid #F0C029;
  @include media-breakpoint-down(md){
    font-size: 20px;
  }
}

.for-free {
  font-weight: 400;
  color: #0C121C;
  margin-bottom: 45px;

  span {
    color: #D80C24;
    font-size: 28px;
    line-height: 1.5;
    padding-left: 10px;
  }
}

.magazine {
  width: 230px;
}

.subscription-page .main-title {
  margin: 50px 0;
}

.padding-10 {
  padding: 0 10px 10px 10px;
}

.program {
  border: 1px solid #8B8D91;
  background: rgba(139, 141, 145, 0.05);
  text-align: center;
  color: #6E7177;
  padding: 20px 0;

  &.active {
    border: 1px solid #B20419;
    background: rgba(178, 4, 25, 0.1);
    text-align: center;
    color: #2A313C;

    .select-btn {
      background: #B20419;
    }
  }

  h4.title {
    font-size: 24px;
    line-height: 1.5;

    span {
      display: block;
    }
  }

  .price {
    .mem {
      margin: 18px 0;
      font-weight: 700;

      span {
        color: #B20419;
        font-size: 28px;
      }
    }

    .origin {
      text-decoration: line-through;
      margin-bottom: 15px;
    }
  }

  .select-btn {
    border: 0;
    font-size: 18px;
    color: #fff;
    background: #8B8D91;
    padding: 15px 30px;
  }
}

.subscription-txt {
  margin: 100px 0;
  font-size: 18px;

  > div {
    margin-bottom: 50px;
  }

  h4.redtitle-line {
    margin-bottom: 20px;
  }

  .txt {
    padding: 0 30px;
  }
}

@media (max-width: 767.98px) {
  .subscription-page .main-title {
    margin: 25px 0 40px 0;
  }

  .for-free span {
    font-size: 24px;
  }
}
</style>

 