<template>
  <div class="row no-gutters">
    <div class="d-flex flex-wrap float-none col-12">
      <template v-for="score in scores" :key="score.id" >
        <router-link class="w-100" :to="`/score/${score.id}?${score.id}`">
          <ItemScore :score="score" class="small" />
        </router-link>
        <hr class="w-100">
      </template>
    </div>
  </div>
  <AppPagination :meta="meta" @page="jump" />
</template>

<script>
import { ScoreService, BrandService } from "@/apis/service";
export default {
  data() {
    return {
      scores: [],
      meta: [],
      filters: [],
      brand_id: null,
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.fetchData(to))
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchData(to)
    next()
  },
  computed: {
    productTypes() { return this.$store.state.productTypes },
    queryPage() {
      return {
        brand_id: this.brand_id,
        // sortedBy: this.sortedBy,
        // limit: 36,
      }
    },
  },
  methods: {
    async fetchData(to) {
      this.brand_id = to.query.brand_id
      const query = Object.assign({ ...this.queryPage }, { page: to.query.page })
      const scoreResp = await ScoreService.query(query)
      const brandResp = await BrandService.query()
      this.scores = scoreResp.data.data
      this.filters = brandResp.data
      this.meta = {
        pagination: {
          total: scoreResp.data.total,
          count: scoreResp.data.total,
          per_page: scoreResp.data.per_page,
          current_page: scoreResp.data.current_page,
          total_pages: scoreResp.data.last_page,
          links: {
            prev: scoreResp.data.prev_page_url,
            next: scoreResp.data.next_page_url,
          }
        }
      }
      this.$store.commit('setBreadcrumb', [{
        name: '査評分',
        to: '/',
      }])
      this.$store.commit('setLoading', false)
    },
    jump(page) {
      const query = Object.assign({ ...this.queryPage }, { page: page })
      this.$router.push({ path: '/score', query })
    },
    updateFilters(value) {

      this.filters = value
      this.fetchData(this.$route)
      const query = Object.assign({ ...this.queryPage })
    },
  },
  watch: {
  },
}
</script>

<script setup>
import ItemScore from '@/components/item/Score.vue'
import AppPagination from '@/components/app/Pagination.vue'


</script>

<style lang="scss" scoped>
.source {
  width: 100% !important;
  // @include media-breakpoint-down(xl){
  //   width: 25% !important;
  // }
  // @include media-breakpoint-down(md){
  //   width: 33% !important;
  // }
  // @include media-breakpoint-down(sm){
  //   width: 50% !important;
  // }
  // @include media-breakpoint-down(xs){
  //   width: 100% !important;
  // }
}

.order-options {}


.content-box {
  background-color: #fff;
  padding: 15px;
}
</style>
