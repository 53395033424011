<template>
<div v-if="expert">
  <div class="expert-box h-100">
    <router-link :to="{ path: '/expert/page', query: { id: expert.id }}">
      <figure class="photo">
        <img :data-splide-lazy="photoUrl" alt="" v-if="slide">
        <img v-lazy="photoUrl" alt="" v-if="!slide">
      </figure>
    </router-link>
    <h4 class="expert-name"><span>{{ expert.name }}</span></h4>
    <div class="expert-title">{{ expert.brief }}</div>

    <ul class="expert-article-group">
      <li v-for="(post, index) in expert.posts.data.slice(0,1)" :key="index">
        <router-link :to="`/article/${post.no}?${post.no}`">
          <span class="d-flex align-items-center">
            <span class="num">{{ index+1 }}</span>
            <span class="text line-3">{{ post.title }}</span>
          </span>
        </router-link>
      </li>
    </ul>
    <div class="more more-panel">
      <router-link :to="{ path: '/expert/page', query: { id: expert.id }}">看更多<i class="fas fa-caret-right ml-2"></i></router-link>
    </div>
    <div class="expert-brief">
      <h2 class="font-weight-bold">{{ expert.name }}</h2>
      <h3 class="font-weight-bold text-secondary">{{ expert.brief }}</h3>
      <div v-html="expert.content"></div>
    </div>
  </div>
</div>
</template>

<script setup>
import dotProp from 'dot-prop'
import ItemArticle from '@/components/item/Article.vue'
import { computed } from "vue";
import { timeKey } from "@/plugins/mixins";

const props = defineProps({
  expert: Object,
  slide: false
});


const photoUrl = computed(() => dotProp.get(props.expert,'media.data.0.large'))

</script>

<style lang="scss" scoped>
.expert-box {
  position: relative;
  text-align: center;
  display: flex;
  // justify-content: space-around;
  flex-direction: column;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
  padding: 30px 10px;
  &::before {
    content: "";
    background-color: #fff;
    top: 130px;
    z-index: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;

  }
  @include tiny{
    flex-direction: column !important;
  }

  @include large{
    display: flex;
    // flex-wrap: wrap;
    &::before {
      display: none;
    }
  }
  @include small{
    display: inline-block;
    &::before {
      display: none;
    }
  }
}
.expert-brief {
  display: none;
  @include large{
    display: block;
    padding-left: 20px;
    @include media-breakpoint-down(sm) {
      margin: auto;
    }
  }
}
.photo {
  @include large{
    width: 300px;
    height: 300px;
    @include media-breakpoint-down(sm) {
      width: 80vw;
      height: 80vw;
    }
  }
  width: 150px;
  height: 150px;
  margin: 0 auto;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  z-index: 5;
  top: -10px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
}

.expert-name {
  @include large{
    display: none;
  }
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 5px;
  margin-bottom: 5px;
  span {
    position: relative;
    @include small{
      &::after {
        display: none;
      }
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 18px;
      right: -11px;
      width: 100%;
      height: 8px;
      background-color: rgba(142, 4, 26, 0.37);
    }
  }
}

.expert-title {
  color: #6E7177;
  font-size: 14px;
  margin-bottom: 5px;
  position: relative;
  z-index: 1;
  @include small{
    display: none;
  }
  @include large{
    display: none;
  }
}

.expert-article-group{
  @include large{
    display: none;
  }
  @include small{
    display: none;
  }
  text-align: left;
  padding: 0;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  li {
    padding: 8px 0;
    font-weight: 300;
    border-bottom: 1px solid #E3E4E5;
    .num {
      font-size: 20px;
      line-height: 27px;
      font-weight: 700;
      padding-right: 10px;
    }
    .text {
      line-height: 30px;
    }
  }
}
.more-panel{
  text-align: right;
  position: relative;
  z-index: 1;
  @include small{
    display: none;
  }
  @include large{
    display: none;
  }
}



</style>
