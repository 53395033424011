<template>
  <div class="row no-gutters">
    <div class="d-flex flex-wrap float-none col-12">
      <ItemScore :score="score" class="w-100" />
    </div>
    <div class="w-100">
      <div class="p-3">
        <h2 class="title-squre">
          <i class="fas fa-square"></i>
          品酒筆記
        </h2>
      </div>
      <div class="compony-list">
        <div v-for="compony_data, compony_name  in score.scores_by_company" class="p-4">
          <h4 class="compony-title">{{ compony_name }}</h4>
          <ul v-for="row in compony_data" class="p-4">
            <router-link :to="`/score/${row.score_id}`" class="w-100">
              <li>{{ row.name_en }}</li>
            </router-link>
          </ul>
          <hr class="w-100">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ScoreService, BrandService } from "@/apis/service";
export default {
  data() {
    return {
      score: [],
      meta: [],
      filters: [],
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.fetchData(to))
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchData(to)
    next()
  },
  computed: {
    productTypes() { return this.$store.state.productTypes },
    queryPage() {
      return {
        orderBy: this.orderBy,
        sortedBy: this.sortedBy,
        limit: 36,
      }
    },
  },
  methods: {
    async fetchData(to) {

      const scoreResp = await ScoreService.get(to.params.no)
      const brandResp = await BrandService.query()
      this.score = scoreResp.data
      this.filters = brandResp.data
      this.meta = scoreResp.data.meta
      this.$store.commit('setBreadcrumb', [{
        name: '査評分',
        to: '/',
      }])
      this.$store.commit('setLoading', false)
    },
    jump(page) {
      const query = Object.assign({ ...this.queryPage }, { page: page })
      this.$router.push({ path: '/product', query })
    },
    chosen(item) {
      this.chosenType = item
      this.$router.push({ path: '/product', query: { type: item.id } })
      this.$refs.fil.$refs.clear.click()
    },
    updateFilters(value) {

      this.filters = value
      this.fetchData(this.$route)
      const query = Object.assign({ ...this.queryPage })
    },
  },
  watch: {
  },
}
</script>

<script setup>
import ItemScore from '@/components/item/Score.vue'
import AppFilterScore from '@/components/app/FilterScore.vue'
import AppPagination from '@/components/app/Pagination.vue'


</script>

<style lang="scss" scoped>
.source {
  width: 100% !important;
  // @include media-breakpoint-down(xl){
  //   width: 25% !important;
  // }
  // @include media-breakpoint-down(md){
  //   width: 33% !important;
  // }
  // @include media-breakpoint-down(sm){
  //   width: 50% !important;
  // }
  // @include media-breakpoint-down(xs){
  //   width: 100% !important;
  // }
}

.order-options {}

.compony-list {
  background-color: #F9F9F9;
  font-size: 18px;

  .compony-title {
    color: #d80c24;
    font-weight: 700;
  }

  ul li {
    list-style: disc;
    padding: 0;
  }
}

.title-squre {
  i {
    color: antiquewhite;
  }
}

.content-box {
  background-color: #fff;
  padding: 15px;
}
</style>