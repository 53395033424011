<template>
<div class="container" v-if="'no' in article">
  <div class="row">
    <div class="col-md-9 col-sm-12">
      <div class="article-header">
          <h1 class="main-title">{{article.title}}</h1>
          <h2 class="sub-title">{{article.subtitle}}</h2>
          <div class="date-anthor">
              <span>{{article.publish_at}}</span>
              <span>By {{ authorName }}</span>
          </div>
      </div>
      <div class="article-tag-group d-none d-sm-block">
        <ul class="d-flex flex-wrap">
          <li v-for="(tag, index) in article.tags.data" :key="index" class="tag-item">
            <router-link :to="{path: '/search/article', query:{ tag: tag.name, tag_id: tag.id }}">{{ tag.name }}</router-link>
          </li>
        </ul>
      </div>
      <div class="youtube-container" v-if="article.youtube">
        <iframe
          :src="`//www.youtube.com/embed/${article.youtube}`"
          frameborder="0"
          allowfullscreen
          class="video">
        </iframe>
      </div>
      <div class="article-body fr-view" >
          <div class="article-txt-container">
              <div v-html="article.content" class="article-txt" ref="article-body-ref">
              </div>
          </div>
      </div>
    </div>

    <div class="col-md-3 col-sm-12">
      <div class="sticky-menu">
        <BoxArticleRelate class="bg-white p-10 my-10 " :articles="newestArticles"/>
        <BoxMagazineCurrent class="bg-white p-10 my-10" />
      </div>
    </div>
    <!-- <div class="col-12" v-if="relateArticles.length">
      <h3 class="text-center">延伸閱讀</h3>
      <ul class="articles-list">
        <li v-for="(article, index) in relateArticles.slice(0,3)" :key="index">
          <a href="http://">{{ article.title }}</a>
        </li>
      </ul>
    </div> -->
    <div class="col-12" v-if="likeProducts.length">
      <h3 class="text-center">你可能也喜歡</h3>
      <CardWine :wines="likeProducts"/>
    </div>
    <div class="col-12" v-if="relateArticles.length">
      <h3 class="text-center">相關文章</h3>
      <div class="d-flex justify-content-left flex-wrap px-15">
        <div class="article-wrapper p-10" v-for="(article, index) in relateArticles" :key="index">
          <ItemArticle class="large" :article="article" />
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import "@/assets/froala_styles.min.css";
import { PostService, CategoryService } from "@/apis/service";
import dotProp from 'dot-prop'
export default {
  props: {
    no: String,
  },
  data() {
    return {
      article: {},
      relateArticles: [],
      likeProducts: [],
      newestArticles: [],
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.fetchData(to))
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchData(to)
    next()
  },
  computed: {
    photoUrl() { return dotProp.get(this.article,'media.data.0.medium') },
    socialPhotoUrl() { return dotProp.get(this.article,'social_image.data.0.large') ||
      dotProp.get(this.article,'media.data.0.large')
    },
    authorName() { return dotProp.get(this.article,'author.data.0.name') },
    publishDate() { return dotProp.get(this.article,'publish_at') },
  },
  methods: {
    async fetchData (to) {
      const articleResp = await PostService.get(to.params.no)
      if (!articleResp.data.data[0]) {
        window.location= "/404.html"
      }
      this.article = articleResp.data.data[0]
      this.likeProducts = this.article.associate_products.data
      this.relateArticles = this.article.associate_posts.data
      const articleMetas = [
        {
          name: "is_sponsored",
          content: this.article.is_sponsored === 1 ? "true" : "false",
        },
      ];
      if (this.article.is_no_index) {
        articleMetas.push({
          name: "robots",
          content: "noindex",
        });
      }
      this.$store.commit('setMeta', {
        title: `${this.article.title}｜酒訊網`,
        og: {
          url: window.location.toString().replace(location.search, ""),
          type: 'website',
          title: `${this.article.title}｜酒訊網`,
          description: this.article.brief,
          image: this.socialPhotoUrl || '',
        },
        meta: [
          {
            property: 'fb:app_id',
            content: 671097574106450,
          },
          {
            name: 'description',
            content: this.article.brief,
          },
          ...articleMetas,
        ],
        link: [
          {
            href: window.location.toString().replace(location.search, ""),
            rel: "canonical",
          }
        ],
      })
      const newestResp = await PostService.query({
        orderBy:'publish_at',
        sortedBy:'desc',
        limit: 12,
      })
      this.newestArticles = newestResp.data.data.filter(row => row.no !== to.params.no).slice(0, 10)
      this.$store.commit('setBreadcrumb',[{
        name: this.article.title,
        to: '/article',
      }])
      this.$store.commit('setLoading', false)
      
    },
    
  },
}
</script>

<script setup>
import BoxArticleRelate from '@/components/box/ArticleRelate.vue'
import BoxMagazineCurrent from '@/components/box/MagazineCurrent.vue'
import CardWine from '@/components/card/Wine.vue'
import ItemArticle from '@/components/item/Article.vue'
import { ref, onMounted,onUpdated } from 'vue'

const el = ref()

function scrollToElement(){
  let currentLocation = window.location.href;
  const hasElementAnchor = currentLocation.includes("#");
  console.log(hasElementAnchor);
  if (hasElementAnchor) {
    const anchorElementId = `${currentLocation.substring(currentLocation.indexOf("#") + 1)}`;
    console.log(anchorElementId);
    const anchorElement = document.getElementById(anchorElementId);
    const yOffset = -200; 
    const y = anchorElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
    if(anchorElement){
        window.scrollTo({top: y, behavior: 'smooth'});
    }
  }
}
  
onUpdated(() => {
  if(el.innerHTML != ''){
    scrollToElement()
  }
})

</script>

<style lang="scss" scoped>
.sticky-menu {
  position: sticky;
  top: 150px;
  padding: 1rem;
}
.articles-list li{
  list-style-type: disc;
}
.youtube-container {
  position: relative;
  width: 100%;
  height: 0;
  margin-top: 15px;
  padding-bottom: 56.25%;
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.article-header {
  .main-title {
    color: #0C121C;
    font-weight: bold;
    text-overflow: ellipsis;
  }

  .sub-title {
    font-weight: bold;
    display: none;
  }
}

.article-header .date-anthor {
  color: #A6A6A6;
  margin-bottom: 20px;
}

.article-header {
  .date-anthor {
    font-weight: 500;
    span {
      color: inherit;
      margin-right: 16px;
    }

    a {
      color: inherit;
      margin-right: 16px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  img {
    width: 100%;
  }
}


.article-body {
  position: relative;
  margin: 10px 0;
}

.article-body {
  .article-shareList {
    position: absolute;
    text-align: center;
    font-weight: 700;
    padding-left: 0;
  }

  .article-shareList-mobile {
    display: none;
    text-align: center;
    font-weight: 700;
    padding-left: 0;
  }

  .article-txt-container {
    // margin-left: 80px;


    .article-txt {
      font-size: 18px;
      line-height: 40px;
      ::v-deep(a){
        color: blue;

        &:hover {
          text-decoration: underline;
        }
      }
      ::v-deep(figure a) {
        color: inherit;
      }


      ::v-deep(table) {
        width: 100%;
        margin-bottom: 0.5em;
        font-size: 1em;
        border-collapse: collapse;
        border-spacing: 0;

        tr {
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          margin: 0;
          padding: 0;
          border: 0;
          font-size: 100%;
          font: inherit;
          vertical-align: baseline;
          text-align: left;
          color: #333;
        }

        thead {
          border-bottom: 3px solid #ddd;
        }

        tr {
          border-bottom: 1px solid #ddd;
          color: #222;
        }

        tr:nth-child(even) {
          background-color: #f6f6f6;
        }

        th {
          font-weight: bold;
          padding: 0.35em;
          font-size: 0.9em;
        }

        td {
          padding: 0.35em;
          font-size: 0.9em;
        }

        .highlight td {
          font-weight: bold;
        }

        th.number {
          text-align: right;
        }
      }

      td.number {
        text-align: right;
      }















      h3 {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 20px;
      }

      img {
        width: 100%;
      }
    }

    .extend-reading {
      color: #0C121C;
      margin-top: 50px;
      border-bottom: 2px solid #D3D5D7;

      .title {
        font-size: 20px;
        position: relative;
        margin-bottom: 15px;

        &::after {
          content: "";
          width: calc(100% - 100px);
          height: 2px;
          background-color: #D3D5D7;
          position: absolute;
          top: 50%;
          right: 0;
        }
      }

      ul {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 25px;

        li {
          margin-bottom: 10px;
          list-style: disc;

          a {
            color: #542836;
            text-decoration: underline;
          }
        }
      }
    }

    .author-introBox {
      display: flex;
      width: 100%;
      background-color: #F3F4F5;
      border-radius: 10px;
      padding: 30px;

      .author-pic figure {
        width: 110px;
        height: 110px;
        margin-right: 30px;
      }

      .author-intro .title {
        color: #6E7177;
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .article-body .article-shareList {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .article-body .article-shareList-mobile {
    display: block;

    ul {
      span {
        margin: 0;
      }

      li {
        margin: 0 0 0 20px;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .article-body .article-txt-container {
    margin-left: 0px;
  }
}

@media (max-width: 575.98px) {
  .article-body .article-txt-container .author-introBox {
    display: block;
    padding: 15px;

    .author-pic figure {
      width: 110px;
      height: 110px;
      margin: 0 auto;
    }
  }
}

.article-wrapper {
  width: 20%;
  @include media-breakpoint-down(sm){
    width: 50%;
  }
  @include media-breakpoint-down(xs){
    width: 100%;
  }
}

.article-banner{
  @include media-breakpoint-up(md){
    display: none;
  }
}
</style>
