export const events = [
    {
        title: '品酒會',
        events: [
            {
                image: "/images/about-event/01.jpg",
                title:"W100 SENSORY TOUR高雄"
            },
            {
                image: "/images/about-event/02.jpg",
                title:"格蘭傑挑戰101"
            },
            {
                image: "/images/about-event/03.jpg",
                title:"葡萄酒生活節<br>酒訊雜誌展示區"
            },
            {
                image: "/images/about-event/04.jpg",
                title:"艾雷島嘉年華"
            },
            {
                image: "/images/about-event/05.jpg",
                title:"葡萄酒生活節"
            },
            {
                image: "/images/about-event/06.jpg",
                title:"金們高粱酒與霹靂上市<br>記者會"
            },
            {
                image: "/images/about-event/07.jpg",
                title:"黑松白鹿品酒會<br>劍菱"
            },
            {
                image: "/images/about-event/08.jpg",
                title:"西班牙拉曼恰酒展"
            },
            {
                image: "/images/about-event/09.jpg",
                title:"日本酒主義"
            },
            {
                image: "/images/about-event/10.jpg",
                title:"格蘭哥尼記者會"
            },
            {
                image: "/images/about-event/11.jpg",
                title:"OMAR選桶品酒會"
            },
            {
                image: "/images/about-event/12.jpg",
                title:"金門高粱品純粹<br>巡迴品酒會"
            },
            {
                image: "/images/about-event/13.jpg",
                title:"格蘭路思質人潮飲聚"
            },
            {
                image: "/images/about-event/14.jpg",
                title:"WSD日本酒大賞"
            },
            {
                image: "/images/about-event/15.jpg",
                title:"TW.WA威士忌大賞"
            },
            {
                image: "/images/about-event/16.jpg",
                title:"威士忌百人盲飲會"
            },
            {
                image: "/images/about-event/17.jpg",
                title:"OMAR威士忌PX<br>十周年記者會"
            },
            {
                image: "/images/about-event/18.jpg",
                title:"禾富酒莊<br>葡萄酒鑑賞會"
            },
            {
                image: "/images/about-event/19.jpg",
                title:"黑松譽加集團<br>記者發表會"
            },
            {
                image: "/images/about-event/20.jpg",
                title:"The Grand Tour<br>「Glen Scotia酒廠巡禮」"
            },
            {
                image: "/images/about-event/21.jpg",
                title:"葡萄牙酒展"
            },
            {
                image: "/images/about-event/22.jpg",
                title:"台酒<br>職人帶路餐酒會"
            },
            {
                image: "/images/about-event/23.jpg",
                title:"2022威士忌大賞頒獎"
            },
            {
                image: "/images/about-event/24.jpg",
                title:"2022 OMAR威士忌館"
            },
        ]
    }
]