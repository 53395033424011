<template>
<div v-if="category" class="section">
    <h3 class="section-title"><span>酒知識</span></h3>
    <div class="row">
      <CardKnowledge v-for="knowledge in category.children.data" :key="knowledge.id" :knowledge="knowledge" class="col-md-4 col-sm-6"/>
    </div>
</div>
</template>

<script setup>
import CardKnowledge from '@/components/card/Knowledge.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const category = computed(() => store.getters.getCategoryById(12))

</script>

<style lang="scss" scoped>

</style>
