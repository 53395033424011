<template>
<div>
  <BannerAd class="py-3" horizontal :banners="banner1Imgs"/>
  <div class="container main-container">
    <ListTag :tags="tags" class="box shadow d-none d-lg-block"/>
    <BoxArticleTop :articles="articles" :splide="size=='small'" class="box shadow"/>
  </div>
  <div class="page-section bg-white">
    <div class="container main-container">
      <ListCategory :splide="size=='small'" />
    </div>
  </div>

  <div class="page-section bg-gray">
    <div class="container main-container">
      <ListExpert :experts="experts" />
    </div>
  </div>
  <div class="page-section bg-white">
    <div class="container main-container">
      <div class="row">
        <ListKnowledge class="col-md-9"/>
        <BannerAd class="col-md-3" vertical :banners="banner2Imgs"/>
      </div>
      <ListWine :wines="wines"/>
    </div>
  </div>
</div>
</template>

<script>
import { PostService,TagService,AuthorService,ProductService,BannerService } from "@/apis/service";
import dotProp from 'dot-prop'
export default {
  data() {
    return {
      unmount: () => {},
      banner1: "",
      banner2: "",
      articles: [],
      tags: [],
      experts: [],
      expertsTemp: [],
      wines: {},
      winesTemp: {
        '84': {
          title: '威士忌',
          data: [],
        },
        '85': {
          title: '葡萄酒',
          data: [],
        },
      },
      platform: '',
      size: '',
      scrollTop: 0,
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.fetchData(to))
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchData(to)
    next()
  },
  computed: {
    banner1Imgs() {
      const banners = dotProp.get(this.banner1,`${this.platform}.data`) || [];
      return banners.map((banner)=> {
        return {
          image: dotProp.get(banner,`original`),
          url: dotProp.get(banner,`custom_properties.url`),
        };
      })
    },
    banner2Imgs() {
      const banners = dotProp.get(this.banner2,`${this.platform}.data`) || [];
      return banners.map((banner)=> {
        return {
          image: dotProp.get(banner,`original`),
          url: dotProp.get(banner,`custom_properties.url`),
        };
      })
    },
  },
  created() {
      window.addEventListener('resize', this.handleResize);
      window.addEventListener('scroll', this.handleScroll);
      this.handleResize();
      this.handleScroll();
  },
  destroyed() {
      window.removeEventListener('resize', this.handleResize);
      window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleResize() {
        this.platform = (window.innerWidth > 768) ?'web':'mobile'
        this.size = (window.innerWidth > 992) ?'large':'small'
    },
    handleScroll() {
      this.scrollTop = document.documentElement.scrollTop;
      if (this.scrollTop > 2000 && !(84 in this.wines)) {
        this.wines = this.winesTemp
      }
      if (this.scrollTop > 1500 && !this.experts.length) {
        this.experts = this.expertsTemp
      }
    },
    async fetchData (to) {
      this.$store.commit('setLoading', true)
      const vm = this
      Promise.all([
        PostService.query({
          orderBy: 'is_top;publish_at',
          sortedBy: 'desc',
          limit: 5,
        }),
        TagService.query({
          limit: 20,
          orderBy: 'sort',
        }),
        AuthorService.query({
          category_id: '56',
          orderBy: 'sort',
          sortedBy: 'desc',
        }),
        ProductService.query({
          // type: '威士忌',
          category_id: 84,
          orderBy: 'is_top;updated_at',
          sortedBy: 'desc;desc',
          limit: 12,
        }),
        ProductService.query({
          // type: '葡萄酒',
          category_id: 85,
          orderBy: 'is_top;updated_at',
          sortedBy: 'desc;desc',
          limit: 12,
        }),
        BannerService.get(1),
        BannerService.get(2),
      ]).then(function(values) {
        vm.articles = values[0].data.data
        vm.tags = values[1].data.data
        vm.expertsTemp = values[2].data.data
        vm.winesTemp['84'].data = values[3].data.data
        vm.winesTemp['85'].data = values[4].data.data
        vm.banner1 = values[5]?.data.data
        vm.banner2 = values[6]?.data.data
        vm.$store.commit('setLoading', false)
      });
    },
  },
}
</script>

<script setup>
import BannerAd from '@/components/banner/Ad.vue'
import BoxArticleTop from '@/components/box/ArticleTop.vue'
import BoxAd from '@/components/box/Ad.vue'
import ListTag from '@/components/list/Tag.vue'
import ListCategory from '@/components/list/Category.vue'
import ListExpert from '@/components/list/Expert.vue'
import ListKnowledge from '@/components/list/Knowledge.vue'
import ListWine from '@/components/list/Wine.vue'

</script>

<style lang="scss" scoped>

</style>
