<template>
<div>
  <div class="container main-container">
    <div class="row">
      <img class="mx-auto d-block col-12 col-md-6" src="/images/404.png">
    </div>

    <div class="d-flex align-items-center justify-content-center h-100">
      <div class="d-flex flex-column">
        <p>您選取的頁面無法開啟。嘗試訪問的頁面可能已被移除，名稱已經變更，或者暫時不可用</p>
        <p>煩請確認您輸入的網址拼寫是正確的。請嘗試殂影下網頁找到您需要的資料：</p>
        <div class="align-self-center">
          <input class="btn btn-outline-danger mx-1 font-weight-bold" type="text" v-model="keyword" placeholder="請輸入關鍵字" @keyup.enter="search">
          <button class="btn btn-danger mx-1 font-weight-bold" @click="search">搜尋</button>
        </div>
      </div>
    </div>

  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      keyword: '酒訊網',
    }
  },
  computed: {
    searchPage() {
      return { path: `/search/all`, query: { keyword: this.keyword }}
    },
  },
  methods: {
    search(e) {
      this.$router.push(this.searchPage)
    },
  },
  mounted() {
    this.$store.commit('setLoading', false)
  }
}
</script>

<style lang="scss" scoped>

</style>
