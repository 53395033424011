<template>
<div class="section">
  <h3 class="section-title"><span>廣告合作</span></h3>
  <div class="content">
    <div class="advertising-sort">
        <div class="row">
            <div class="col-md-3 col-sm-6 col-12">
                <div class="item">
                    <img src="@/assets/images/digital.png" alt="">
                    <h2 class="title">數位廣告服務</h2>
                    <div class="txt">酒客網：廣告刊登、廣編撰寫、影音廣告。</div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
                <div class="item">
                    <img src="@/assets/images/graphic.png" alt="">
                    <h2 class="title">平面雜誌廣告服務</h2>
                    <div class="txt">酒訊雜誌：廣告刊登、廣編撰寫。</div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
                <div class="item">
                    <img src="@/assets/images/activity.png" alt="">
                    <h2 class="title">酒類相關活動</h2>
                    <div class="txt">品酒會、媒體餐會、記者會、大型酒展、教育課程。</div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
                <div class="item">
                    <img src="@/assets/images/competition.png" alt="">
                    <h2 class="title">酒類競賽</h2>
                    <div class="txt">TW.WA威士忌大賞、WSD葡萄酒大賞、WSD日本酒大賞。</div>
                </div>
            </div>
        </div>
    </div>
    <div class="advertising-txt">
        <p class="intro">
            <span>「從消費者到產業全面涵蓋」</span> 
            <span>酒傳媒從2006年雜誌創刊至今，對酒類產業的相關活動已是經驗十足。</span>
            <span>我們不只了解酒商的語言，省去許多溝通上的時間！</span>
            <span>也因為過往所舉辦的活動中，掌握到非常精準的客群與通路能為活動帶來最好的效益與目標達成。</span>
        </p>
        <p class="connect">
            <span class="txt-bold">合作專線：02-2509-5777 轉 廣告部
            </span>
            <span class="txt-bold">公司信箱：Service@ch-9.net</span>
        </p>
    </div>
  </div>
</div>
</template>

<script setup>
</script>

<style lang="scss" scoped>

.advertising-sort {
  margin-top: 40px;

  .item {
    background: #F3F4F5;
    padding: 10px 15px 25px;
    text-align: center;
    margin-bottom: 20px;

    img {
      max-width: 240px;
      width: 100%;
      margin-bottom: 5px;
    }

    .title {
      font-size: 24px;
      line-height: 1.5;
      font-weight: 400;
      @include media-breakpoint-down(sm) {
        font-size: 20px;
      }
    }

    .txt {
      text-align: left;
      color: #6E7177;
      min-height: 48px;
      height: 100%;
    }
  }
}

.advertising-txt {
  text-align: center;
  font-size: 18px;
  line-height: 48px;
  margin-top: 50px;

  span {
    display: block;
  }

  .connect {
    font-weight: 700;
    line-height: 32px;
  }
}

</style>
