<template>
<div class=" bg-gray">
  <div class="title-box d-lg-none">
    <div class="filter-title">
      <span class="title-image">
        <img src="/images/score.png" alt="">
        <span class="title-text">查評分</span>
      </span>
      <span class="title-more" @click="toggleMore()">MORE</span>
    </div>
  </div>
  <div class="content-box">
    <div class="row no-gutters">
      <div class="col-12 "><div @click="$router.go(-1)" class="back-btn float-right"><i class="fas fa-arrow-left" aria-hidden="true"></i>上一頁</div></div>
      <div class="col-md-3 col-sm-12">
        <span class="title-image d-none d-lg-block">
          <img src="/images/score.png" alt="">
          <span class="title-text">查評分</span>
        </span>
        <AppFilterScore :class="{active: isOpen, 'side-nav': true}"
      :filters="filters"
    />
      </div>
      <div class="col-md-9 col-sm-12">
        <router-view></router-view>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { ScoreService, BrandService } from "@/apis/service";
export default {
  data() {
    return {
      scores: [],
      meta: [],
      filters: [],
      brand_id: null,
      isOpen: false,
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.fetchData(to))
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchData(to)
    next()
  },
  computed: {
    productTypes() {  return this.$store.state.productTypes },
    queryPage() {
      return {
        brand_id: this.brand_id,
        // sortedBy: this.sortedBy,
        // limit: 36,
      }
    },
  },
  methods: {
    async fetchData(to) {
      this.isOpen = false
      this.brand_id = to.query.brand_id
      const query = Object.assign({...this.queryPage}, {page: to.query.page})
      const scoreResp = await ScoreService.query(query)
      const brandResp = await BrandService.query()
      this.scores = scoreResp.data.data
      this.filters = brandResp.data
      this.meta = {
        pagination: {
            total: scoreResp.data.total,
            count: scoreResp.data.total,
            per_page: scoreResp.data.per_page,
            current_page: scoreResp.data.current_page,
            total_pages: scoreResp.data.total,
            links: {
                prev: scoreResp.data.prev_page_url,
                next: scoreResp.data.next_page_url,
            }
        }
      }
      this.$store.commit('setBreadcrumb',[{
        name: '査評分',
        to: '/',
      }])
      this.$store.commit('setLoading', false)
    },
    jump(page) {
      const query = Object.assign({...this.queryPage}, {page: page})
      this.$router.push({ path: '/score', query})
    },
    toggleMore() {
      this.isOpen = !this.isOpen
    },
    updateFilters(value) {
      this.filters = value
      this.fetchData(this.$route)
      const query = Object.assign({...this.queryPage})
    },
  },
  watch: {
  },
}
</script>

<script setup>
import ItemScore from '@/components/item/Score.vue'
import AppFilterScore from '@/components/app/FilterScore.vue'
import AppPagination from '@/components/app/Pagination.vue'


</script>

<style lang="scss" scoped>
.back-btn{
  width: 70px; color: #a6a8a7;cursor: pointer; padding: 3px;
}
.source {
  width: 100% !important;
  // @include media-breakpoint-down(xl){
  //   width: 25% !important;
  // }
  // @include media-breakpoint-down(md){
  //   width: 33% !important;
  // }
  // @include media-breakpoint-down(sm){
  //   width: 50% !important;
  // }
  // @include media-breakpoint-down(xs){
  //   width: 100% !important;
  // }
}
.title-box{
  @include media-breakpoint-down(lg){
    position: fixed;
    z-index: 10;
    background: white;
    left: 0;
    width: 100%;
    padding-top: 25px;
    top: 45px;
  }
}
.title-text{
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: 700;

  @include media-breakpoint-down(md){
    font-size: 5vw;
  // font-size: 25px;
    // top: 6px;
    // right: 65px;
  }
  @include media-breakpoint-down(lg){

  }

}

.title-image{
  display: inline-block;
  position: relative;
  text-align: center;
  color: white;
  width: 100%;
  @include media-breakpoint-down(lg){
    width: 85%;
  }
}

.title-more{
  width: 15%;
  position: relative;
  text-align: center;
  display: none;
  color: blue;
  font-weight: bolder;
  text-shadow: 1px 1px 2px black;
  @include media-breakpoint-down(lg){
    display: inline-block;
  }
}


.content-box {
  background-color: #fff;
  padding: 15px;
  @include media-breakpoint-down(lg){
    margin-top: 15vw;
  }
}

.side-nav {
  @include media-breakpoint-down(lg){
    position: fixed;
    z-index: 100;
    top: 130px;
    left: 0;
    bottom: 10vh;
    background: white;
    font-size: 18px;
    overflow: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: none;
    &.active {
      display: block;
    }
  }
  padding: 30px;
  width: 300px;

  // @include media-breakpoint-up(xl){
  //   top: 110px;
  // }
  // @include media-breakpoint-down(xl){
  //   top: 66px;
  // }
  // @include media-breakpoint-down(md){
  //   top: 44.525px;
  //   font-size: 16px;
  //   width: 250px;
  // }
}
</style>
