<template>
<div>
  <div class="container main-container">
    <ListEventRecent :events="course"/>
    <ListEventPast :events="endCourse"/>
  </div>
</div>
</template>

<script>
import { CourseService } from "@/apis/service";
export default {
  // props: {
  //   no: String,
  // },
  data() {
    return {
      course: [],
      endCourse: [],
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.fetchData(to))
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchData(to)
    next()
  },
  methods: {
    async fetchData (to) {
      const vm = this
      Promise.all([
        CourseService.query({
          category_id: '87',
          orderBy: 'id',
          sortedBy: 'desc',
        }),
        CourseService.query({
          category_id: '86',
          orderBy: 'id',
          limit: 3,
          sortedBy: 'desc',
        })
      ]).then(function(values) {
        vm.course = values[0].data.data
        vm.endCourse = values[1].data.data
        vm.$store.commit('setLoading', false)
      });
    },
  },
}
</script>

<script setup>
import ListEventRecent from '@/components/list/EventRecent.vue'
import ListEventPast from '@/components/list/EventPast.vue'
import { useStore } from 'vuex'
const store = useStore()
store.commit('setBreadcrumb',[{
  name: '找活動',
  to: '/event',
}])


</script>
