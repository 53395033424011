<template>
<div v-if="articles" class="section">
  <template v-if="splide">
    <splide :options="options" class="item-list row no-gutters" :slides="articles">
    <slide v-for="(article, index) in articles" :key="index" >
      <div class="col-lg-6 col-sm-12 content-wrapper">
        <ItemArticle :article="article"  class="large" />
      </div>
    </slide>
  </splide>
  </template>
  <template v-else>
    <div class="item-list row no-gutters">
    <div class="col-lg-6 col-sm-12 content-wrapper">
      <ItemArticle v-for="(article, index) in articles.slice(0, 1)" :article="article" :key="index" class="large" />
    </div>
    <div class="col-lg-6 col-sm-12 content-wrapper">
      <ItemArticle v-for="(article, index) in articles.slice(1, 4)" :article="article" :key="index" />
    </div>
  </div>
  </template>
</div>
</template>

<script setup>
import ItemArticle from '@/components/item/Article.vue'
import { Splide, SplideSlide as slide } from '@splidejs/vue-splide';

const props = defineProps({
  articles: Array,
  splide: Boolean,
});

const options = {
  type: 'slide',
  arrows: false,
  interval: 5000,
  autoplay: true,
  rewindSpeed: 0,
  rewind:true,
}
</script>

<style lang="scss" scoped>
.content-wrapper {
  display: flex;
  flex-direction: column;
  @include media-breakpoint-down(md){
    padding: 0 15px;
  }
}
.item-list {
  margin: 0;
  @include media-breakpoint-down(md){
    padding: 15px 0;
  }
}
</style>