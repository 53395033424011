<template>
  <div class="section">
    <div :class="splide ? [] : ['row', 'content-wrapper']">
      <CardCategory v-for="category in categories" :splide="splide" :key="category" :category-id="category"
        class="col-lg-4 col-12" />
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import CardCategory from '@/components/card/Category.vue'
// const categories = [4,66,71,77,109,90]
const categories = [4, 309, 285, 75, 62, 68, 57]
const props = defineProps({
  splide: Boolean,
});
</script>

<style lang="scss" scoped></style>
