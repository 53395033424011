<template>
<div>
  <div class="container main-container">
    <BoxPrivacy class="box"/>
  </div>
</div>
</template>

<script setup>
import BoxPrivacy from '@/components/box/Privacy.vue'
import { useStore } from 'vuex'
const store = useStore()
store.commit('setBreadcrumb',[{
  name: '服務條款與隱私權聲明',
  to: '/privacy',
}])
store.commit('setLoading', false)
</script>

<style lang="scss" scoped>

</style>
