export const routerMixin = {
  beforeRouteEnter(to, from, next) {
    console.log('enter')
    next(vm => vm.fetchData(to))
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchData(to)
    next()
  },
  created() {
    console.log('component hook called')
  }
  // async fetchData (to) {
  // },
}


const currentDate = new Date();
export const timeKey = currentDate.getTime();