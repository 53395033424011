<template>
<div v-if="knowledge">
  <h4 class="category-title">
    <span>{{ knowledge.name }}</span>
    <router-link class="more" :to="{ path: '/article', query: { category: knowledge.id }}">看更多<i class="fas fa-caret-right ml-2"></i></router-link>
  </h4>
  <div class="">
      <div class="image-wrapper" v-if="image">
          <img v-lazy="image" alt="">
      </div>
      <ul class="article-titles">
          <li v-for="post in knowledge.posts.data" :key="post.no" class="title">
              <router-link :to="`/article/${post.no}?${post.no}`"><span class="line-1 d-block" >{{ post.title }}</span></router-link>
          </li>
      </ul>
  </div>
</div>
</template>

<script setup>

import { defineProps, computed } from "vue";
import dotProp from 'dot-prop'
import { timeKey } from "@/plugins/mixins";
const image = computed(() => dotProp.get(props.knowledge,'posts.data.0.media.data.0.medium'))

const props = defineProps({
  knowledge: Object
  // id: String,
  // posts: Object,
});
</script>

<style lang="scss" scoped>
.category-title {
  font-size: 24px;
  line-height: 42px;
  font-weight: 700;
  border-bottom: 5px solid #6E7177;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.image-wrapper {
  cursor: pointer;
}
li {
  padding: 8px 5px;
  color: #0C121C;
  &:first-child {
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding: 8px 5px;
  }


  &:nth-child(even){
    background-color: #F3F4F5;
  }
}

</style>
