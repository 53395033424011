<template>
<div class="container main-container bg-gray">
  <div class="d-flex p-3" style="vertical-align: middle;">
    <h3 class="py-1">査酒款</h3>
    <AppTabPage :list="productTypes" :chosen="chosenType" @change="chosen"/>
    
    <div class="title-box d-lg-none">
      <div class="filter-title">
        <span class="title-more" @click="toggleMore()">進階搜尋</span>
      </div>
    </div>
  </div>




  <div class="content-box">
    <div class="row no-gutters">


      <div class="col-md-2 col-sm-12 content-left">
        <AppFilterWine ref="fil"
        @update="updateFilters"
        :type="chosenType.id"
        :category="filterCategory"
        :filters="filters"
         :class="{active: isOpen, 'side-nav': true}"
      />
      </div>


      <div class="col-md-10 col-sm-12">
        <div class="row no-gutters">
          <div class="col-12">
            <div class="order-options form-group float-right">
              <select class="form-control" @change="orderChange($event)">
                <option value="3">新舊:由新至舊</option>
                <option value="4">新舊:由舊至新</option>
                <option value="1">價格:由低至高</option>
                <option value="2">價格:由高至低</option>
              </select>
            </div>
          </div>
          <div class="d-flex flex-wrap px-15 float-none col-12">
            <ItemWine v-for="wine in wines" :key="wine.id" class="product" :wine="wine"/>
          </div>
        </div>
        <AppPagination :meta="meta" @page="jump"/>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { ProductService, CategoryService } from "@/apis/service";
export default {
  data() {
    return {
      filterCategory: {},
      wines: [],
      meta: [],
      filters: (this.$route.query.spec) ? this.$route.query.spec.split(","): [],
      orderBy: 'updated_at;price',
      sortedBy: 'desc;asc',
      chosenType: {
        id: this.$route.query.type,
        name: this.$route.query.type,
      },
      isOpen: false,
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.fetchData(to))
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchData(to)
    next()
  },
  computed: {
    productTypes() {  return this.$store.state.productTypes },
    filtersOption() {
      let specs = []
      for (const [key, value] of Object.entries(this.filters)) {
        if(value.length) {
          specs.push(value.map(
            x => x.id
          ).join(','));
        }
      }
      return specs.join(',')
    },
    queryPage() {
      return {
        type: this.chosenType.id,
        category_id: this.chosenType.id,
        orderBy: this.orderBy,
        sortedBy: this.sortedBy,
        limit: 36,
        spec: this.filters.join(','),
      }
    },
  },
  methods: {
    async fetchData (to) {
      this.chosenType = {
        id: to.query.type,
        name: to.query.type,
      }
      const query = Object.assign({...this.queryPage}, {page: to.query.page})
      const productResp = await ProductService.query(query)
      this.wines = productResp.data.data
      this.meta = productResp.data.meta
      this.$store.commit('setBreadcrumb',[{
        name: '査酒款',
        to: '/',
      }])
      this.$store.commit('setLoading', false)
    },
    jump(page) {
      const query = Object.assign({...this.queryPage}, {page: page})
      this.$router.push({ path: '/product', query})
    },
    toggleMore() {
      this.isOpen = !this.isOpen
    },
    chosen(item) {
      this.chosenType = item
      this.$router.push({ path: '/product', query: { type: item.id }})
      this.$refs.fil.$refs.clear.click()
    },
    updateFilters(value) {

      this.filters = value
      this.fetchData(this.$route)
      const query = Object.assign({...this.queryPage})
    },
    orderChange(event) {
      switch (event.target.value) {
        case '1':
          this.orderBy = 'price;updated_at'
          this.sortedBy = 'asc;asc'
          break;
        case '2':
          this.orderBy = 'price;updated_at'
          this.sortedBy = 'desc;asc'
          break;
        case '3':
          this.orderBy = 'updated_at;price'
          this.sortedBy = 'desc;asc'
          break;
        case '4':
          this.orderBy = 'updated_at;price'
          this.sortedBy = 'asc;asc'
          break;
      }
      this.fetchData(this.$route)
    }
  },
  watch: {
    '$route.query.type': {
      handler: async function(type) {
        let categoryId
        switch (type) {
          case '84':
            categoryId = 102
            break;
          case '85':
            categoryId = 103
            break;
        }
        const categoryResp = await CategoryService.get(categoryId)
        this.filterCategory = categoryResp.data.data[0]
      },
      deep: true,
      immediate: true
    }
  },
}
</script>

<script setup>
import ItemWine from '@/components/item/Wine.vue'
import AppFilterWine from '@/components/app/FilterWine.vue'
import AppPagination from '@/components/app/Pagination.vue'
import AppTabPage from '@/components/app/TabPage.vue'


</script>

<style lang="scss" scoped>
.product {
  width: 20% !important;
  @include media-breakpoint-down(xl){
    width: 25% !important;
  }
  @include media-breakpoint-down(md){
    width: 33% !important;
  }
  @include media-breakpoint-down(sm){
    width: 50% !important;
  }
  @include media-breakpoint-down(xs){
    width: 100% !important;
  }
}

.order-options {

}

.title-more{
  width: 30%;
  position: absolute;
  right: 0;
  font-size: 1.5rem;
  text-align: center;
  color: blue;
  display: none;
  @include media-breakpoint-down(lg){
    display: inline-block;
  }
}

.content-box {
  background-color: #fff;
  padding: 15px;
  margin: 15px;
}

.side-nav {
  @include media-breakpoint-down(md){
    position: fixed;
    z-index: 100;
    top: 130px;
    left: 0;
    bottom: 10vh;
    background: white;
    font-size: 18px;
    overflow: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: none;
    width: 100%;
    padding: 10px;
    &.active {
      display: block;
    }
  }

}

</style>
