
<template>
<div :class="direction" v-if="banners">
  <Splide :has-track="false" :options="options" ref="splide">
    <SplideTrack>

      <template v-for="banner in banners" :key="banner.image">
        <SplideSlide >
          <div class="ad-cover">
            <a :href="banner.url" target="_blank"><img :src="banner.image" alt=""></a>
          </div>
        </SplideSlide>
      </template>


    </SplideTrack>
  </Splide>
</div>
</template>

<script setup>
import { defineProps, computed, ref, onUpdated } from "vue";
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide';
const props = defineProps({
  horizontal: Boolean,
  vertical: Boolean,
  image: String,
  banners: Object,
});
const direction = computed(() => {
  if (props.horizontal) {
    return 'horizontal'
  }
  if (props.vertical) {
    return 'vertical'
  }
  return 'horizontal'
})
const splide = ref();
const options = {
  type    : 'loop',
  autoplay: true,
  perPage : 1,
  arrows: false,
  pauseOnHover: false,
  interval: 3000,
  preloadPages: 1,
  cover: true,
}
onUpdated(() => {
  if (splide.value && splide.value.splide) {
    splide.value.splide.refresh()
    const { Autoplay } = splide.value.splide.Components;
    Autoplay.play();
  }
});

</script>


<style lang="scss" scoped>
.ad-cover {
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
  .horizontal &{
    width: 100%;
    overflow: hidden;
  }
  .vertical &{
    width: 100%;
  }
}

</style>


