<template>
<div class="wine-card">
  <h4 class="category-title" v-if="title">
    <span>{{ title }}</span>
    <router-link class="more" :to="{ path: '/product', query: { type: category }}">看更多<i class="fas fa-caret-right ml-2"></i></router-link>
  </h4>
  <div class="wine-wrapper">
    <template v-if="wines.length > 5">
      <splide :options="options" class="slide-wine" :slides="wines">
        <slide v-for="wine in wines" :key="wine">
          <ItemWine :wine="wine" :slide="true"/>
        </slide>
      </splide>
    </template>
    <template v-else>
        <ItemWine class="splide__slide" v-for="wine in wines" :key="wine" :wine="wine"/>
    </template>

  </div>
</div>
</template>

<script setup>
import ItemWine from '@/components/item/Wine.vue'
import { Splide, SplideSlide as slide } from '@splidejs/vue-splide';
const props = defineProps({
  title: String,
  category: String,
  wines: {
    type: Array,
    default: [],
  },
});
const options = {
  type: 'loop',
  lazyLoad: 'sequential',
  preloadPages: 4,
  autoWidth: true,
  pagination: false,
}

</script>

<style lang="scss" scoped>
.category-title {
  font-size: 24px;
  line-height: 42px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  line-height: 30px;
  font-weight: 400;
  padding: 8px 5px;
}


::v-deep(.splide__slide) {
  display: inline-block;
  width: 185px;
  padding: 0px 5px;
  @include media-breakpoint-down(xl){
    width: 222px;
  }
  @include media-breakpoint-down(lg){
    width: 231px;
  }
  @include media-breakpoint-down(md){
    width: 255px;
  }
}

</style>
