<template>
<div>
  <div class="container main-container">
    <BoxAbout />
    <ListEvent />
  </div>
</div>
</template>

<script setup>
import BoxAbout from '@/components/box/About.vue'
import ListEvent from '@/components/list/Event.vue'
import { useStore } from 'vuex'
const store = useStore()
store.commit('setBreadcrumb',[{
  name: '關於我們',
  to: '/about',
}])
store.commit('setLoading', false)
</script>

<style lang="scss" scoped>

</style>
