<template>
<div>
  <ul :class="{ 'd-none': isOpen['more']}">
    <li class="sort-item" v-for="(filter, key) in filters" :key="key" :class="{active: isOpen[key]}" >
      <div class="sort-title" @click="toggle(key)">
        <span class="sort-icons">{{ filter.name }}</span>
        <i class="icon-double-arrow"></i>
      </div>
      <ul class="sort-item-title">
        <li v-for="(alphabet, index) in filter.options" :key="index">
          <div class="alphabet-title">
            <span class="alphabet-icons">{{ alphabet.name }}</span>
            <i class="fa fa-angle-up"></i>
          </div>
          <ul class="sort-item-title">
            <li v-for="(brand, index) in alphabet.options" :key="index">
              <label class="container">
                <router-link :to="`/score?brand_id=${brand.brand_id}`">
                  {{ brand.brand_name }}({{ brand.counts }})
                </router-link>
              </label>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</div>
</template>

<script setup>
import { ref, reactive, watch, computed } from "vue";
const props = defineProps({
  filters: Object,
});
const initialState = {}
const emit = defineEmits(['update']);
const resultArray = ref(props.filters)
const result = reactive({})
const isOpen = reactive({})
const filterTags = computed(() => Object.values(result).flatMap(x => x))
const filters = computed(() => {

  const unordered = {}
  const options = {}
  for (const [category, data] of Object.entries(props.filters)) {
    if (Array.isArray(data)) {
      isOpen[category] = true
      options[category] = {
        name: category,
        options: orderOpeions(data)
      };
    }
  }
  return options
})
watch(resultArray, (result, oldResult) => {
  emit('update', resultArray)
})

const orderOpeions = (data) => {
  const unordered = {}
  data.forEach(element => {
    const category = element.brand_name.charAt(0).toUpperCase()
    if (!(category in unordered)) {
      isOpen[category] = true
      unordered[category] = {
        name: category,
        options: []
      }
    }
    unordered[category].options.push(element)
  })
  const ordered = Object.keys(unordered).sort().reduce(
    (obj, key) => { 
      obj[key] = unordered[key]; 
      return obj;
    }, 
    {}
  );
  return ordered
}

const toggle = (key) => {
  isOpen[key] = !isOpen[key]
}

</script>

<style lang="scss" scoped>


.sort-item {
  font-size: 18px;
  font-weight: 700;
  overflow: hidden;
  .sort-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #2A313C;
    padding-bottom: 8px;
    cursor: pointer;
    margin-bottom: 20px;
  }
    .icon-double-arrow {
      transform: rotate(0deg);
      transition: 0.5s;
    }
  .sort-item-title {
    max-height: 0;
    transition: max-height 0.5s ease-out;
  }
  &.active {
    .sort-item-title {
      max-height: 500px;
      transition: max-height 0.5s ease-in;
    }
    .icon-double-arrow {
      transform: rotate(180deg);
      transition: 0.5s;
    }
  }
}

.alphabet-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #6E7177;
  margin-bottom: 10px;
  width: 90%;
  margin:  10px auto;
  font-size: small;
  font-weight: 400;
}

.sort-item .sort-item-title li {
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 35px;
  }

  .container {
    display: block;
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 18px;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 1px solid #6E7177;
    background-color: #fff;
  }

  .container input:checked ~ .checkmark {
    background-color: #6E7177;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .container {
    input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark:after {
      left: 5px;
      top: 0px;
      width: 7px;
      height: 13px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.filter-box {
  background-color: #F9F9F9;
  padding: 10px;
  margin-bottom: 30px;
  .wine-tag-group {
    padding: 0;
    ul {
      margin: 0 0 6px 0;
      li {
        background-color: #2A313C;
        padding: 5px;
        margin: 5px 3px 5px 0;
        color: #FFF;
        font-size: 14px;
        &::before {
          content: "X";
          color: #fff;
          margin: 0 7px 0 2px;
        }
      }
    }
    .filter-clear {
      border: 0;
      background: transparent;
      color: #B20419;
      font-weight: 700;
      text-decoration: underline;
    }
  }
}
</style>