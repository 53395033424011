
<template>
<div class="bg-gray">
  <div class="container main-container mb-5">
    <div class="p-3 d-flex justify-content-between">
      <h2 class="redtitle-line">嚴選達人</h2>
    </div>
    <div class="d-flex flex-wrap">
      <CardExpert class="small" v-for="expert in experts" :key="expert.name" :expert="expert"/>
    </div>
  </div>
</div>
</template>

<script>
import { PostService,TagService,AuthorService,ProductService } from "@/apis/service";
export default {
  // props: {
  //   no: String,
  // },
  data() {
    return {
      articles: [],
      experts: [],
      meta: [],
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.fetchData(to))
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchData(to)
    next()
  },
  methods: {
    async fetchData (to) {
      const vm = this
      Promise.all([
        AuthorService.query({
          category_id: '56',
          orderBy: 'sort',
          sortedBy: 'desc',
        }),
      ]).then(function(values) {
        vm.experts = values[0].data.data
        vm.articles = values[1].data.data
        vm.meta = values[1].data.meta
        vm.$store.commit('setLoading', false)
      });
    },
    jump(page) {
      this.$router.push({ path: '/expert', query: { category: 56 ,page }})
    }
  },
}
</script>

<script setup>
import BoxMagazineCurrent from '@/components/box/MagazineCurrent.vue'
import CardExpert from '@/components/card/Expert.vue'
import ItemArticle from '@/components/item/Article.vue'
import AppPagination from '@/components/app/Pagination.vue'
</script>

<style lang="scss" scoped>
.article-content .article-body {
  position: relative;
  margin: 10px 0;
}

.article-inside-page .article-content .article-body {
  .article-shareList {
    position: absolute;
    text-align: center;
    font-weight: 700;
    padding-left: 0;
  }

  .article-shareList-mobile {
    display: none;
    text-align: center;
    font-weight: 700;
    padding-left: 0;
  }

  .article-txt-container {
    margin-left: 80px;

    .article-txt {
      font-size: 18px;
      line-height: 32px;

      h3 {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 20px;
      }

      img {
        width: 100%;
      }
    }

    .extend-reading {
      color: #0C121C;
      margin-top: 50px;
      border-bottom: 2px solid #D3D5D7;

      .title {
        font-size: 20px;
        position: relative;
        margin-bottom: 15px;

        &::after {
          content: "";
          width: calc(100% - 100px);
          height: 2px;
          background-color: #D3D5D7;
          position: absolute;
          top: 50%;
          right: 0;
        }
      }

      ul {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 25px;

        li {
          margin-bottom: 10px;
          list-style: disc;

          a {
            color: #542836;
            text-decoration: underline;
          }
        }
      }
    }

    .author-introBox {
      display: flex;
      width: 100%;
      background-color: #F3F4F5;
      border-radius: 10px;
      padding: 30px;

      .author-pic figure {
        width: 110px;
        height: 110px;
        margin-right: 30px;
      }

      .author-intro .title {
        color: #6E7177;
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .article-inside-page .article-content .article-body .article-shareList {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .article-inside-page .article-content .article-body .article-shareList-mobile {
    display: block;

    ul {
      span {
        margin: 0;
      }

      li {
        margin: 0 0 0 20px;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .article-inside-page .article-content .article-body .article-txt-container {
    margin-left: 0px;
  }
}

@media (max-width: 575.98px) {
  .article-inside-page .article-content .article-body .article-txt-container .author-introBox {
    display: block;
    padding: 15px;

    .author-pic figure {
      width: 110px;
      height: 110px;
      margin: 0 auto;
    }
  }
}
</style>
